import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'

const RESOURCE_NAME = 'categoria'
const ENDPOINT = '/acl/configuracoes/categorias'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingCategoriaSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingCategoriaSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_CATEGORIA_SUCCESS = 'LOADING_CATEGORIA_SUCCESS'
export function loadingCategoriaSuccess(categoria) {
  return {
    type: LOADING_CATEGORIA_SUCCESS,
    categoria,
  }
}

export const DESTROYING_CATEGORIA_SUCCESS = 'DESTROYING_CATEGORIA_SUCCESS'
export function destroyingCategoriaSuccess(categoriaId) {
  return {
    type: DESTROYING_CATEGORIA_SUCCESS,
    categoriaId,
  }
}

export function fetchMultipleCategoria() {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchCategoria(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createCategoria(categoria) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT, 'POST', categoria).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateCategoria(id, categoria) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`, 'PUT', categoria).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyCategoria(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(`${ENDPOINT}/${id}`, 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const categoria = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_CATEGORIA_SUCCESS: {
      const { categoria: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_CATEGORIA_SUCCESS:
      return state.delete(action.categoriaId.toString())

    default:
      return state
  }
}

export default categoria
