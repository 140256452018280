import * as React from 'react'
import PropTypes from 'prop-types'

import { SvgIcon } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

export const TelemetriaIcon = props => {
  const theme = useTheme()
  const color = theme.palette.type === 'light' ? '#36343A' : '#fff'

  return (
    <SvgIcon viewBox='0 5 40 40' {...props}>
      <defs>
        <clipPath id='a'>
          <path d='M680 252H725V296H680z' />
        </clipPath>
      </defs>
      <g
        clipPath='url(#a)'
        transform='translate(-682 -250)'
        fill={props?.cliente ? color : '#36343A'}>
        <path d='M701.563 265.796v1.833c.328 0 .609-.046.937-.046.328 0 .61 0 .938.046v-1.833c1.828.091 3.562.458 5.156 1.054l-.703 1.65c.61.23 1.172.458 1.734.733l.703-1.696c.89.413 1.688.917 2.485 1.467l2.015-1.97A20.723 20.723 0 00702.5 263c-11.39 0-20.625 9.03-20.625 20.167V285h2.813v-1.833c0-1.88.328-3.713.89-5.409l1.735.688c.187-.596.421-1.146.656-1.696l-1.735-.688a16.538 16.538 0 012.813-4.262l1.312 1.283c.422-.458.844-.916 1.313-1.329l-1.313-1.283a18.683 18.683 0 014.313-2.934l.703 1.696a19.558 19.558 0 011.734-.733l-.703-1.696c1.594-.596 3.328-.916 5.157-1.008zM719.422 271.617l-2.016 1.97c.516.78.985 1.605 1.36 2.43l-1.735.733c.235.55.47 1.146.657 1.696l1.734-.688c.562 1.696.89 3.53.89 5.409V285h2.813v-1.833c0-4.309-1.36-8.25-3.703-11.55zM701.188 282.342c-.094.046-.141.137-.235.229-.656.779-.562 1.925.235 2.612a1.95 1.95 0 002.671-.229l14.579-16.912-17.25 14.3z' />
      </g>
    </SvgIcon>
  )
}

TelemetriaIcon.propTypes = {
  cliente: PropTypes.bool,
}
