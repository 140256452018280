import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import toQueryString from './utils/toQueryString'
import Actions from './utils/actions'

const RESOURCE_NAME = 'pedagio'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
    case Actions.CREATE:
      return '/acl/pedagios'

    case Actions.SHOW:
    case Actions.UPDATE:
    case Actions.DESTROY:
      return `/acl/pedagios/${id}`

    default:
      return `/acl/pedagios/${id}/${action}`
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingPedagioSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingPedagioSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

const rejectCalculation = (dispatch, error) => {
  dispatch(loadingPedagioSuccess(error.response.data.pedagio))
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

const resolveDownload = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  return Promise.resolve(resource)
}

export const LOADING_PEDAGIO_SUCCESS = 'LOADING_PEDAGIO_SUCCESS'
export function loadingPedagioSuccess(pedagio) {
  return {
    type: LOADING_PEDAGIO_SUCCESS,
    pedagio,
  }
}

export const DESTROYING_PEDAGIO_SUCCESS = 'DESTROYING_PEDAGIO_SUCCESS'
export function destroyingPedagioSuccess(pedagioId) {
  return {
    type: DESTROYING_PEDAGIO_SUCCESS,
    pedagioId,
  }
}

export function fetchPedagio(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.SHOW, id)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchPedagioByToken(token) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`/acl/pedagios/token/${token}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchMultiplePedagio(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.INDEX) + toQueryString(params)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createPedagio(pedagio) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.CREATE), 'POST', pedagio).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updatePedagio(id, pedagio) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.UPDATE, id), 'PUT', pedagio).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyPedagio(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(endpoint(Actions.DESTROY, id), 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

export function calculatePedagio(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('calculate', id), 'POST').then(
      resource => resolve(dispatch, resource),
      error => rejectCalculation(dispatch, error)
    )
  }
}

export function confirmPedagio(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('confirm', id), 'POST').then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function undoPedagio(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('undo', id), 'POST').then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function assignPedagio(id, userId) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${endpoint('assign', id)}/${userId}`, 'POST').then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function downloadPedagio(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.callWithResponse(endpoint('download', id)).then(
      resource => resolveDownload(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

const pedagio = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_PEDAGIO_SUCCESS: {
      const { pedagio: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_PEDAGIO_SUCCESS:
      return state.delete(action.pedagioId.toString())

    default:
      return state
  }
}

export default pedagio
