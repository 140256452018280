import React from 'react'
import PropTypes from 'prop-types'

import {
  QueryClientProvider as ReactQueryClientProvider,
  QueryClient,
} from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) =>
        ![401, 404].includes(error.status) && failureCount < 2,
    },
  },
})

export const QueryClientProvider = ({ children }) => (
  <ReactQueryClientProvider client={queryClient}>
    {children}
    <ReactQueryDevtools initialIsOpen={false} />
  </ReactQueryClientProvider>
)

QueryClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
