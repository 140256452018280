import { useSessionState } from 'contexts/SessionContext'

const useSession = () => {
  const state = useSessionState()
  return state?.session?.payload?.sub
}

export const useRoles = () => {
  const state = useSessionState() ?? {}
  return state?.session?.payload?.roles ?? []
}

export const useUser = () => {
  const state = useSessionState() ?? {}
  return state?.user ?? {}
}

export default useSession
