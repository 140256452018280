import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'

const RESOURCE_NAME = 'submercado'
const ENDPOINT = '/submercados'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingSubmercadoSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingSubmercadoSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_SUBMERCADO_SUCCESS = 'LOADING_SUBMERCADO_SUCCESS'
export function loadingSubmercadoSuccess(submercado) {
  return {
    type: LOADING_SUBMERCADO_SUCCESS,
    submercado,
  }
}

export const DESTROYING_SUBMERCADO_SUCCESS = 'DESTROYING_SUBMERCADO_SUCCESS'
export function destroyingSubmercadoSuccess(submercadoId) {
  return {
    type: DESTROYING_SUBMERCADO_SUCCESS,
    submercadoId,
  }
}

export function fetchMultipleSubmercado() {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchSubmercado(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createSubmercado(submercado) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT, 'POST', submercado).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateSubmercado(id, submercado) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`, 'PUT', submercado).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroySubmercado(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(`${ENDPOINT}/${id}`, 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const submercado = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_SUBMERCADO_SUCCESS: {
      const { submercado: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_SUBMERCADO_SUCCESS:
      return state.delete(action.submercadoId.toString())

    default:
      return state
  }
}

export default submercado
