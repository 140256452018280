import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import toQueryString from './utils/toQueryString'

const RESOURCE_NAME = 'unidade'
const CONTATO_RESOURCE_NAME = 'contatos_unidade'
const ENDPOINT = '/acl/unidades'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingUnidadeSuccess(resource))
  return Promise.resolve(resource)
}

const resolveContatos = (dispatch, resource) => {
  dispatch(loadingSuccess(CONTATO_RESOURCE_NAME))
  dispatch(loadingContatosUnidadeSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingUnidadeSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_CONTATOS_UNIDADE_SUCCESS =
  'LOADING_CONTATOS_UNIDADE_SUCCESS'
export function loadingContatosUnidadeSuccess(contatos) {
  return {
    type: LOADING_CONTATOS_UNIDADE_SUCCESS,
    contatos,
  }
}

export const LOADING_UNIDADE_SUCCESS = 'LOADING_UNIDADE_SUCCESS'
export function loadingUnidadeSuccess(unidade) {
  return {
    type: LOADING_UNIDADE_SUCCESS,
    unidade,
  }
}

export const DESTROYING_UNIDADE_SUCCESS = 'DESTROYING_UNIDADE_SUCCESS'
export function destroyingUnidadeSuccess(unidadeId) {
  return {
    type: DESTROYING_UNIDADE_SUCCESS,
    unidadeId,
  }
}

export function fetchMultipleUnidade(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT + toQueryString(params)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchUnidade(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchContatosUnidade(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(CONTATO_RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}/contatos_unidade`).then(
      resource => resolveContatos(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createUnidade(unidade) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT, 'POST', unidade).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateUnidade(id, unidade) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`, 'PUT', unidade).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyUnidade(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(`${ENDPOINT}/${id}`, 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

export function assignUnidade(id, userId) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}/assign/${userId}`, 'POST').then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchTagsPontosUnidade(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}/tags_pontos`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

const unidade = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_UNIDADE_SUCCESS: {
      const { unidade: payload } = action
      return mergePayload(state, payload)
    }

    case LOADING_CONTATOS_UNIDADE_SUCCESS: {
      const { contatos } = action
      return mergePayload(state, { contatos: contatos })
    }

    case DESTROYING_UNIDADE_SUCCESS:
      return state.delete(action.unidadeId.toString())

    default:
      return state
  }
}

export default unidade
