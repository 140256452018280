import React from 'react'
import PropTypes from 'prop-types'
import { isValidElementType } from 'react-is'
import { Route, Redirect } from 'react-router-dom'
import { isAllowed } from 'utils/authorization'
import { useSessionState } from 'contexts/SessionContext'

export const PrivateRoute = ({ component: Component, permission, ...rest }) => {
  const { user: currentUser } = useSessionState()
  const isAuthenticated = localStorage.getItem('token')
  const isAuthorized = !permission || isAllowed(currentUser, [permission])

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          isAuthorized ? (
            <Component {...props} />
          ) : (
            Object.keys(currentUser).length !== 0 ? (
              <h1>Acesso negado.</h1>
            )
            : (
              <>
                <h1>Não conseguimos carregar seus dados.</h1>
                <h3>Tente atualizar a página.</h3>
                <p>Se o problema persistir, contate um administrador.</p>
              </>   
            )
          )
        ) : (
          <Redirect
            to={{
              pathname: '/session/destroy',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: (props, propName) => {
    if (props[propName] && !isValidElementType(props[propName])) {
      // eslint-disable-next-line
      return new Error(
        "Invalid prop 'component' supplied to 'PrivateRoute': the prop is not a valid React component"
      )
    }
  },
  location: PropTypes.object,
  permission: PropTypes.string,
}

export default PrivateRoute
