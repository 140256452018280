import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import toQueryString from './utils/toQueryString'

const RESOURCE_NAME = 'vigenciaBandeira'
const ENDPOINT = '/gestao_bandeiras/vigencias'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingVigenciaBandeiraSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingVigenciaBandeiraSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_VIGENCIA_BANDEIRA_SUCCESS = 'LOADING_VIGENCIA_BANDEIRA_SUCCESS'
export function loadingVigenciaBandeiraSuccess(vigenciaBandeira) {
  return {
    type: LOADING_VIGENCIA_BANDEIRA_SUCCESS,
    vigenciaBandeira,
  }
}

export const DESTROYING_VIGENCIA_BANDEIRA_SUCCESS = 'DESTROYING_VIGENCIA_BANDEIRA_SUCCESS'
export function destroyingVigenciaBandeiraSuccess(vigenciaBandeiraId) {
  return {
    type: DESTROYING_VIGENCIA_BANDEIRA_SUCCESS,
    vigenciaBandeiraId,
  }
}

export function fetchMultipleVigenciaBandeira(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT + toQueryString(params)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchVigenciaBandeira(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createVigenciaBandeira(vigenciaBandeira) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT, 'POST', vigenciaBandeira).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateVigenciaBandeira(id, vigenciaBandeira) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`, 'PUT', vigenciaBandeira).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyVigenciaBandeira(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(`${ENDPOINT}/${id}`, 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const vigenciaBandeira = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_VIGENCIA_BANDEIRA_SUCCESS: {
      const { vigenciaBandeira: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_VIGENCIA_BANDEIRA_SUCCESS:
      return state.delete(action.vigenciaBandeiraId.toString())

    default:
      return state
  }
}

export default vigenciaBandeira
