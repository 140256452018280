import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import Actions from './utils/actions'

const RESOURCE_NAME = 'contatoConcessionaria'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
    case Actions.CREATE:
      return `/concessionarias/${id}/contatos`

    case Actions.SHOW:
    case Actions.UPDATE:
    case Actions.DESTROY:
      return `/contatos/${id}`

    default:
      return ''
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingContatoConcessionariaSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingContatoConcessionariaSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_CONTATO_CONCESSIONARIA_SUCCESS = 'LOADING_CONTATO_CONCESSIONARIA_SUCCESS'
export function loadingContatoConcessionariaSuccess(contatoConcessionaria) {
  return {
    type: LOADING_CONTATO_CONCESSIONARIA_SUCCESS,
    contatoConcessionaria,
  }
}

export const DESTROYING_CONTATO_CONCESSIONARIA_SUCCESS =
  'DESTROYING_CONTATO_CONCESSIONARIA_SUCCESS'
export function destroyingContatoConcessionariaSuccess(contatoConcessionariaId) {
  return {
    type: DESTROYING_CONTATO_CONCESSIONARIA_SUCCESS,
    contatoConcessionariaId,
  }
}

export function fetchMultipleContatoConcessionaria(parentId) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.INDEX, parentId)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchContatoConcessionaria(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.SHOW, id)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createContatoConcessionaria(parentId, contatoConcessionaria) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(
      endpoint(Actions.CREATE, parentId),
      'POST',
      contatoConcessionaria
    ).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateContatoConcessionaria(id, contatoConcessionaria) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.UPDATE, id), 'PUT', contatoConcessionaria).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyContatoConcessionaria(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(endpoint(Actions.DESTROY, id), 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const contatoConcessionaria = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_CONTATO_CONCESSIONARIA_SUCCESS: {
      const { contatoConcessionaria: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_CONTATO_CONCESSIONARIA_SUCCESS:
      return state.delete(action.contatoConcessionariaId.toString())

    default:
      return state
  }
}

export default contatoConcessionaria
