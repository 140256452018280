import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import toQueryString from './utils/toQueryString'
import Actions from './utils/actions'

const RESOURCE_NAME = 'relatorio'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
    case Actions.CREATE:
      return '/acl/reports'

    case Actions.SHOW:
    case Actions.UPDATE:
    case Actions.DESTROY:
      return `/acl/reports/${id}`

    default:
      return `/acl/reports/${id}/${action}`
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingRelatorioSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingRelatorioSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_RELATORIO_SUCCESS = 'LOADING_RELATORIO_SUCCESS'
export function loadingRelatorioSuccess(relatorio) {
  return {
    type: LOADING_RELATORIO_SUCCESS,
    relatorio,
  }
}

export const DESTROYING_RELATORIO_SUCCESS = 'DESTROYING_RELATORIO_SUCCESS'
export function destroyingRelatorioSuccess(relatorioId) {
  return {
    type: DESTROYING_RELATORIO_SUCCESS,
    relatorioId,
  }
}

export function fetchRelatorio(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.SHOW, id)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchRelatorioByToken(token) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`/acl/reports/share/${token}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchMultipleRelatorio(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.INDEX) + toQueryString(params)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createRelatorio(relatorio) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.CREATE), 'POST', relatorio).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateRelatorio(id, relatorio) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.UPDATE, id), 'PUT', relatorio).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function confirmRelatorio(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('confirm', id), 'POST').then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function undoRelatorio(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('undo', id), 'POST').then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyRelatorio(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(endpoint(Actions.DESTROY, id), 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

export function assignRelatorio(id, userId) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${endpoint('assign', id)}/${userId}`, 'POST').then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

const relatorio = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_RELATORIO_SUCCESS: {
      const { relatorio: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_RELATORIO_SUCCESS:
      return state.delete(action.relatorioId.toString())

    default:
      return state
  }
}

export default relatorio
