import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import Actions from './utils/actions'

const RESOURCE_NAME = 'valorIndexador'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
    case Actions.CREATE:
      return `/acl/indexadores/${id}/valores_indexador`

    case Actions.SHOW:
    case Actions.UPDATE:
    case Actions.DESTROY:
      return `/acl/valores_indexador/${id}`

    default:
      return ''
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingValorIndexadorSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingValorIndexadorSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_VALOR_INDEXADOR_SUCCESS = 'LOADING_VALOR_INDEXADOR_SUCCESS'
export function loadingValorIndexadorSuccess(valorIndexador) {
  return {
    type: LOADING_VALOR_INDEXADOR_SUCCESS,
    valorIndexador,
  }
}

export const DESTROYING_VALOR_INDEXADOR_SUCCESS =
  'DESTROYING_VALOR_INDEXADOR_SUCCESS'
export function destroyingValorIndexadorSuccess(valorIndexadorId) {
  return {
    type: DESTROYING_VALOR_INDEXADOR_SUCCESS,
    valorIndexadorId,
  }
}

export function fetchMultipleValorIndexador(parentId) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.INDEX, parentId)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchValorIndexador(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.SHOW, id)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createValorIndexador(parentId, valorIndexador) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(
      endpoint(Actions.CREATE, parentId),
      'POST',
      valorIndexador
    ).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateValorIndexador(id, valorIndexador) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.UPDATE, id), 'PUT', valorIndexador).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyValorIndexador(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(endpoint(Actions.DESTROY, id), 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const valorIndexador = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_VALOR_INDEXADOR_SUCCESS: {
      const { valorIndexador: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_VALOR_INDEXADOR_SUCCESS:
      return state.delete(action.valorIndexadorId.toString())

    default:
      return state
  }
}

export default valorIndexador
