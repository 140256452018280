import { Map } from 'immutable'

export const LOADING = 'LOADING'
export function loading(key) {
  return {
    type: LOADING,
    key,
  }
}

export const LOADING_SUCCESS = 'LOADING_SUCCESS'
export function loadingSuccess(key) {
  return {
    type: LOADING_SUCCESS,
    key,
  }
}

export const LOADING_FAILURE = 'LOADING_FAILURE'
export function loadingFailure(key, error) {
  return {
    type: LOADING_FAILURE,
    key,
    error,
  }
}

const initialState = Map({})

export default function status(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return state.mergeDeep({
        [action.key]: {
          isLoading: true,
        },
      })

    case LOADING_SUCCESS:
      return state.mergeDeep({
        [action.key]: {
          error: null,
          isLoading: false,
          lastUpdated: new Date().getTime(),
        },
      })

    case LOADING_FAILURE:
      return state.mergeDeep({
        [action.key]: {
          isLoading: false,
          error: action.error,
        },
      })

    default:
      return state
  }
}
