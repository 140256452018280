import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import toQueryString from './utils/toQueryString'
import Actions from './utils/actions'

const RESOURCE_NAME = 'rateio'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
      return '/acl/rateios'

    case Actions.UPDATE:
      return `/acl/rateios/${id}`

    default:
      return `/acl/rateios/${id}/${action}`
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingRateioSuccess(resource))
  return Promise.resolve(resource)
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_RATEIO_SUCCESS = 'LOADING_RATEIO_SUCCESS'
export function loadingRateioSuccess(rateio) {
  return {
    type: LOADING_RATEIO_SUCCESS,
    rateio,
  }
}

export const DESTROYING_RATEIO_SUCCESS = 'DESTROYING_RATEIO_SUCCESS'
export function destroyingRateioSuccess(rateioId) {
  return {
    type: DESTROYING_RATEIO_SUCCESS,
    rateioId,
  }
}

export function fetchMultipleRateio(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.INDEX) + toQueryString(params)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function downloadMultipleRateio(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.callWithResponse(
      `/acl/rateios/download${toQueryString(params)}`
    ).then(
      resource => {
        dispatch(loadingSuccess(RESOURCE_NAME))
        return Promise.resolve(resource)
      },
      error => reject(dispatch, error)
    )
  }
}

export function updateRateio(id, rateio) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.UPDATE, id), 'PUT', rateio).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function confirmRateio(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('confirm', id), 'POST').then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function finishRateio(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('finish', id), 'POST').then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function undoRateio(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('undo', id), 'POST').then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

const rateio = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_RATEIO_SUCCESS: {
      const { rateio: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_RATEIO_SUCCESS:
      return state.delete(action.rateioId.toString())

    default:
      return state
  }
}

export default rateio
