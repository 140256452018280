import Honeybadger from 'honeybadger-js'

const config = {
  api_key: process.env.REACT_APP_HONEYBADGER_KEY
    ? process.env.REACT_APP_HONEYBADGER_KEY
    : '',
  environment: process.env.REACT_APP_STAGE
    ? process.env.REACT_APP_STAGE
    : 'development',
  revision: process.env.REACT_APP_COMMIT_SHA,
}

const HoneybadgerConfig = Honeybadger.configure(config)

Honeybadger.beforeNotify(notice => {
  if (notice.environment === 'development') {
    return false
  }
})

export default HoneybadgerConfig
