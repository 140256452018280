import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'

const RESOURCE_NAME = 'funcao'
const ENDPOINT = '/funcoes'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingFuncaoSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingFuncaoSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_FUNCAO_SUCCESS = 'LOADING_FUNCAO_SUCCESS'
export function loadingFuncaoSuccess(funcao) {
  return {
    type: LOADING_FUNCAO_SUCCESS,
    funcao,
  }
}

export const DESTROYING_FUNCAO_SUCCESS = 'DESTROYING_FUNCAO_SUCCESS'
export function destroyingFuncaoSuccess(funcaoId) {
  return {
    type: DESTROYING_FUNCAO_SUCCESS,
    funcaoId,
  }
}

export function fetchMultipleFuncao() {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchFuncao(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createFuncao(funcao) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT, 'POST', funcao).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateFuncao(id, funcao) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`, 'PUT', funcao).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyFuncao(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(`${ENDPOINT}/${id}`, 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const funcao = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_FUNCAO_SUCCESS: {
      const { funcao: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_FUNCAO_SUCCESS:
      return state.delete(action.funcaoId.toString())

    default:
      return state
  }
}

export default funcao
