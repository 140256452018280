import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import MuiLinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles(theme => ({
  loadingIndicador: {
    position: 'fixed',
    width: '100%',
    left: 0,
    zIndex: theme.zIndex.appBar + 2,
    top: 56,
    '@media (min-width:0px) and (orientation: landscape)': {
      top: 48,
    },
    '@media (min-width:600px)': {
      top: 64,
    },
  },
}))

const LinearProgress = ({ isLoading }) => {
  const classes = useStyles()
  const theme = useTheme()
  const color = theme.palette.type === 'light' ? 'secondary' : 'primary'
  if (!isLoading) return null
  return (
    <MuiLinearProgress color={color} className={classes.loadingIndicador} />
  )
}

LinearProgress.propTypes = {
  isLoading: PropTypes.bool,
}

export default LinearProgress
