import axios from 'axios'

import { addNotification } from 'redux/modules/notifier'
import getMessage from './getMessage'
import getKey from './getKey'
import history from 'utils/history'

const getRawToken = () => {
  if (!localStorage.token) return null
  const token = JSON.parse(localStorage.token)
  return token.jwt
}

export default {
  setupInterceptors: (store, instance = axios) => {
    instance.interceptors.response.use(
      function (response) {
        const message = getMessage(response)
        const key = getKey(response)

        if (message && key) {
          store.dispatch(
            addNotification({
              key,
              message,
              options: { variant: 'success' },
            })
          )
        }

        return Promise.resolve(response)
      },
      function (error) {
        const message = getMessage(error.response, false)
        const key = getKey(error.response)

        if (message && key) {
          store.dispatch(
            addNotification({
              key,
              message,
              options: { variant: 'error' },
            })
          )
        }

        if (error.response && error.response.status === 401) {
          history.push('/session/destroy')
        }

        return Promise.reject(error)
      }
    )

    instance.interceptors.request.use(function (config) {
      config.headers['Authorization'] = `Bearer ' ${getRawToken()}`
      return config
    })
  },
}
