import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import toQueryString from './utils/toQueryString'
import Actions from './utils/actions'

const RESOURCE_NAME = 'consolidado'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
    case Actions.CREATE:
      return '/acl/consolidados'

    case Actions.SHOW:
    case Actions.UPDATE:
    case Actions.DESTROY:
      return `/acl/consolidados/${id}`

    default:
      return `/acl/consolidados/${id}/${action}`
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingConsolidadoSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingConsolidadoSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_CONSOLIDADO_SUCCESS = 'LOADING_CONSOLIDADO_SUCCESS'
export function loadingConsolidadoSuccess(consolidado) {
  return {
    type: LOADING_CONSOLIDADO_SUCCESS,
    consolidado,
  }
}

export const DESTROYING_CONSOLIDADO_SUCCESS = 'DESTROYING_CONSOLIDADO_SUCCESS'
export function destroyingConsolidadoSuccess(consolidadoId) {
  return {
    type: DESTROYING_CONSOLIDADO_SUCCESS,
    consolidadoId,
  }
}

export function fetchConsolidado(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.SHOW, id)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchMultipleConsolidado(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.INDEX) + toQueryString(params)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createConsolidado(consolidado) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.CREATE), 'POST', consolidado).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateConsolidado(id, consolidado) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.UPDATE, id), 'PUT', consolidado).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function confirmConsolidado(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('confirm', id), 'POST').then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function undoConsolidado(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('undo', id), 'POST').then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyConsolidado(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(endpoint(Actions.DESTROY, id), 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

export function assignConsolidado(id, userId) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${endpoint('assign', id)}/${userId}`, 'POST').then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

const consolidado = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_CONSOLIDADO_SUCCESS: {
      const { consolidado: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_CONSOLIDADO_SUCCESS:
      return state.delete(action.consolidadoId.toString())

    default:
      return state
  }
}

export default consolidado
