import React, { useReducer } from 'react'
import PropTypes from 'prop-types'

import * as sessionClient from 'api/clients/sessionClient'

const initialState = {
  session: {},
  user: {},
  isLoading: false,
  error: [],
}

const SessionStateContext = React.createContext()
const SessionDispatchContext = React.createContext(() => {
  throw new Error('Forgot to wrap component in SessionContext.Provider')
})

const sessionReducer = (state, action) => {
  switch (action.type) {
    case 'loading': {
      return { ...state, isLoading: true }
    }

    case 'loading failure': {
      return { ...state, isLoading: false, error: action.error, session: {} }
    }

    case 'loading success': {
      const { token } = action

      localStorage.setItem('token', JSON.stringify(token))

      const session = {
        raw: token.jwt,
        header: JSON.parse(window.atob(token.jwt.split('.')[0])),
        payload: JSON.parse(window.atob(token.jwt.split('.')[1])),
      }

      return { ...state, isLoading: false, session }
    }

    case 'loading user success': {
      const { user } = action
      return { ...state, isLoading: false, user: user }
    }

    case 'destroy session':
      localStorage.removeItem('token')
      return initialState

    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const SessionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(sessionReducer, initialState)
  return (
    <SessionStateContext.Provider value={state}>
      <SessionDispatchContext.Provider value={dispatch}>
        {children}
      </SessionDispatchContext.Provider>
    </SessionStateContext.Provider>
  )
}

const useSessionState = () => {
  const context = React.useContext(SessionStateContext)
  if (context === undefined) {
    throw new Error('useSessionState must be used within a SessionProvider')
  }
  return context
}

const useSessionDispatch = () => {
  const context = React.useContext(SessionDispatchContext)
  if (context === undefined) {
    throw new Error('useSessionDispatch must be used within a SessionProvider')
  }
  return context
}

SessionProvider.propTypes = {
  children: PropTypes.node,
}

const useCreateSession = () => {
  const dispatch = useSessionDispatch()
  return React.useCallback(
    async (email, password) => {
      dispatch({ type: 'loading' })
      try {
        const { data } = await sessionClient.createSession(email, password)
        dispatch({ type: 'loading success', token: data })
        return Promise.resolve(data)
      } catch (error) {
        dispatch({ type: 'loading failure', error })
        return Promise.reject(error)
      }
    },
    [dispatch]
  )
}

const useDestroySession = () => {
  const dispatch = useSessionDispatch()
  return React.useCallback(() => dispatch({ type: 'destroy session' }), [
    dispatch,
  ])
}

const useFetchUser = () => {
  const dispatch = useSessionDispatch()
  return React.useCallback(
    async id => {
      dispatch({ type: 'loading' })
      try {
        const { data } = await sessionClient.fetchUser(id)
        dispatch({ type: 'loading user success', user: data })
        return Promise.resolve(data)
      } catch (error) {
        dispatch({ type: 'loading failure', error })
        return Promise.reject(error)
      }
    },
    [dispatch]
  )
}

export {
  SessionProvider,
  useSessionState,
  useSessionDispatch,
  useCreateSession,
  useDestroySession,
  useFetchUser,
}
