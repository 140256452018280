import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import Actions from './utils/actions'

const RESOURCE_NAME = 'parametroUF'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
    case Actions.CREATE:
      return `/uf/${id}/parametros_uf`

    case Actions.SHOW:
    case Actions.UPDATE:
    case Actions.DESTROY:
      return `/parametros_uf/${id}`

    default:
      return ''
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingParametroUFSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingParametroUFSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_PARAMETRO_UF_SUCCESS = 'LOADING_PARAMETRO_UF_SUCCESS'
export function loadingParametroUFSuccess(parametroUF) {
  return {
    type: LOADING_PARAMETRO_UF_SUCCESS,
    parametroUF,
  }
}

export const DESTROYING_PARAMETRO_UF_SUCCESS =
  'DESTROYING_PARAMETRO_UF_SUCCESS'
export function destroyingParametroUFSuccess(parametroUFId) {
  return {
    type: DESTROYING_PARAMETRO_UF_SUCCESS,
    parametroUFId,
  }
}

export function fetchMultipleParametroUF(parentId) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.INDEX, parentId)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchParametroUF(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.SHOW, id)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createParametroUF(parentId, parametroUF) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(
      endpoint(Actions.CREATE, parentId),
      'POST',
      parametroUF
    ).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateParametroUF(id, parametroUF) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.UPDATE, id), 'PUT', parametroUF).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyParametroUF(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(endpoint(Actions.DESTROY, id), 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const parametroUF = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_PARAMETRO_UF_SUCCESS: {
      const { parametroUF: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_PARAMETRO_UF_SUCCESS:
      return state.delete(action.parametroUFId.toString())

    default:
      return state
  }
}

export default parametroUF
