import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'

const RESOURCE_NAME = 'gerador'
const ENDPOINT = '/geradores'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingGeradorSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingGeradorSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_GERADOR_SUCCESS = 'LOADING_GERADOR_SUCCESS'
export function loadingGeradorSuccess(gerador) {
  return {
    type: LOADING_GERADOR_SUCCESS,
    gerador,
  }
}

export const DESTROYING_GERADOR_SUCCESS = 'DESTROYING_GERADOR_SUCCESS'
export function destroyingGeradorSuccess(geradorId) {
  return {
    type: DESTROYING_GERADOR_SUCCESS,
    geradorId,
  }
}

export function fetchMultipleGerador() {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchGerador(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createGerador(gerador) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT, 'POST', gerador).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateGerador(id, gerador) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`, 'PUT', gerador).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyGerador(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(`${ENDPOINT}/${id}`, 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const gerador = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_GERADOR_SUCCESS: {
      const { gerador: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_GERADOR_SUCCESS:
      return state.delete(action.geradorId.toString())

    default:
      return state
  }
}

export default gerador
