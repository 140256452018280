import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import Actions from './utils/actions'

const RESOURCE_NAME = 'custoPonto'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
      return `/acl/custos/${id}/custos_ponto`

    case 'import':
      return '/acl/custos_ponto/import'

    default:
      return ''
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingCustoPontoSuccess(resource))
  return Promise.resolve(resource)
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_CUSTO_PONTO_SUCCESS = 'LOADING_CUSTO_PONTO_SUCCESS'
export function loadingCustoPontoSuccess(custoPonto) {
  return {
    type: LOADING_CUSTO_PONTO_SUCCESS,
    custoPonto,
  }
}

export function fetchMultipleCustoPonto(parentId) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.INDEX, parentId)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function importCustosPonto(multipleCustoPonto) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('import'), 'POST', multipleCustoPonto).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

const custoPonto = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_CUSTO_PONTO_SUCCESS: {
      const { custoPonto: payload } = action
      return mergePayload(state, payload)
    }

    default:
      return state
  }
}

export default custoPonto
