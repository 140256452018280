import React from 'react'

import { Session, Password, Main, Share } from 'loadable'

import { PrivateRoute } from 'components/PrivateRoute/PrivateRoute'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import history from 'utils/history'
import ScrollToTop from 'components/ScrollToTop/ScrollToTop'
import ReactGA from 'react-ga4'
import { useSessionState } from 'contexts/SessionContext'

const AppRouter = () => {
  const { user = {} } = useSessionState()
  initializeAnalytics({ user })

  return (
    <Router history={history}>
      <ScrollToTop>
        <Switch>
          <PrivateRoute path='/app' component={Main} />
          <Route path='/session' component={Session} />
          <Route path='/password' component={Password} />
          <Route path='/share' component={Share} />
          <Route exact path='/' component={() => <Redirect to='/app' />} />
          <Route render={() => <Redirect to='/session/new' />} />
        </Switch>
      </ScrollToTop>
    </Router>
  )
}

const initializeAnalytics = ({ user }) => {
  const REACT_APP_GOOGLE_TRACK = process.env.REACT_APP_GOOGLE_TRACK || null

  if (REACT_APP_GOOGLE_TRACK && user?.id) {
    ReactGA.initialize([
      {
        trackingId: REACT_APP_GOOGLE_TRACK,
        gaOptions: { user: user?.id },
      },
    ])
  }

  // Initialize google analytics page view tracking
  history.listen(location => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname })
    ReactGA.send('pageview')
  })
}

export default AppRouter
