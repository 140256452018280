import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import Actions from './utils/actions'
import toQueryString from './utils/toQueryString'

const RESOURCE_NAME = 'volume'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
    case Actions.CREATE:
      return `/acl/contratos/${id}/volumes`

    case Actions.SHOW:
    case Actions.UPDATE:
    case Actions.DESTROY:
      return `/acl/volumes/${id}`

    default:
      return ''
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingVolumeSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingVolumeSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_VOLUME_SUCCESS = 'LOADING_VOLUME_SUCCESS'
export function loadingVolumeSuccess(volume) {
  return {
    type: LOADING_VOLUME_SUCCESS,
    volume,
  }
}

export const DESTROYING_VOLUME_SUCCESS = 'DESTROYING_VOLUME_SUCCESS'
export function destroyingVolumeSuccess(volumeId) {
  return {
    type: DESTROYING_VOLUME_SUCCESS,
    volumeId,
  }
}

export function fetchMultipleVolume(parentId, params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(
      endpoint(Actions.INDEX, parentId) + toQueryString(params)
    ).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchVolume(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.SHOW, id)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createVolume(parentId, volume) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.CREATE, parentId), 'POST', volume).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateVolume(id, volume) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.UPDATE, id), 'PUT', volume).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyVolume(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(endpoint(Actions.DESTROY, id), 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const volume = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_VOLUME_SUCCESS: {
      const { volume: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_VOLUME_SUCCESS:
      return state.delete(action.volumeId.toString())

    default:
      return state
  }
}

export default volume
