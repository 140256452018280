/* eslint-disable no-console */
import React from 'react'
import { fetchMeta } from './API'

const INTERVAL = 5 * 60 * 1000 // 5 minutes

const fetchAndCheck = async () => {
  const {
    REACT_APP_VERSION: version,
    REACT_APP_COMMIT_SHA: commit,
    REACT_APP_STAGE: stage,
  } = process.env
  const meta = await fetchMeta()
  const isNewVersionAvailable =
    version !== meta.version || commit !== meta.commit || stage !== meta.stage

  if (isNewVersionAvailable) {
    console.log('New version found! Reloading in 5 seconds...')
    setTimeout(() => {
      navigator.serviceWorker
        .getRegistrations()
        .then(registrations => {
          for (let registration of registrations) {
            registration.unregister()
          }
        })
        .then(() => window.location.reload(true))
    }, 5000) // eslint-disable-line
  }
}

const VersionChecker = () => {
  const timer = React.useRef(null)

  React.useEffect(() => {
    fetchAndCheck()
    timer.current = setInterval(async () => fetchAndCheck(), INTERVAL)
  }, [])

  React.useEffect(() => {
    return function cleanup() {
      clearInterval(timer.current)
      timer.current = null
    }
  })

  return null
}

export default VersionChecker
