import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import Actions from './utils/actions'

const RESOURCE_NAME = 'vigenciaTarifa'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
    case Actions.CREATE:
      return `/concessionarias/${id}/tarifas`

    case Actions.SHOW:
    case Actions.UPDATE:
    case Actions.DESTROY:
      return `/tarifas/${id}`

    case 'import':
      return '/tarifas/import'

    default:
      return ''
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingVigenciaTarifaSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingVigenciaTarifaSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_VIGENCIA_TARIFA_SUCCESS = 'LOADING_VIGENCIA_TARIFA_SUCCESS'
export function loadingVigenciaTarifaSuccess(vigenciaTarifa) {
  return {
    type: LOADING_VIGENCIA_TARIFA_SUCCESS,
    vigenciaTarifa,
  }
}

export const DESTROYING_VIGENCIA_TARIFA_SUCCESS =
  'DESTROYING_VIGENCIA_TARIFA_SUCCESS'
export function destroyingVigenciaTarifaSuccess(vigenciaTarifaId) {
  return {
    type: DESTROYING_VIGENCIA_TARIFA_SUCCESS,
    vigenciaTarifaId,
  }
}

export function fetchMultipleVigenciaTarifa(parentId) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.INDEX, parentId)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchVigenciaTarifa(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.SHOW, id)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createVigenciaTarifa(parentId, vigenciaTarifa) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(
      endpoint(Actions.CREATE, parentId),
      'POST',
      vigenciaTarifa
    ).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateVigenciaTarifa(id, vigenciaTarifa) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.UPDATE, id), 'PUT', vigenciaTarifa).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyVigenciaTarifa(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(endpoint(Actions.DESTROY, id), 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

export function importVigenciaTarifa(multipleVigenciaTarifa) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('import'), 'POST', multipleVigenciaTarifa).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

const vigenciaTarifa = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_VIGENCIA_TARIFA_SUCCESS: {
      const { vigenciaTarifa: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_VIGENCIA_TARIFA_SUCCESS:
      return state.delete(action.vigenciaTarifaId.toString())

    default:
      return state
  }
}

export default vigenciaTarifa
