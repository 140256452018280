import isObjectEmpty from 'utils/isObjectEmpty'

const COEFF = 2500 // in milliseconds

const getKey = (response = {}) => {
  const { status } = response

  if (isObjectEmpty(response)) return ''

  const ok = 200 >= status && status < 300
  const timeStamp = new Date(
    ok ? null : Math.round(new Date().getTime() / COEFF) * COEFF
  )
  return `${status}_${timeStamp.getTime()}`
}

export default getKey
