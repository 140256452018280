import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '../../Loader/LinearProgress'
import { addNotification } from 'redux/modules/notifier'
import { useDispatch } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import CardLink from '../../CardLink/CardLink'
import { TelemetriaIcon } from './icones/Telemetria'
import { getCredenciais } from './utils/credenciais'

export const EngecompLink = () => {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch()

  return (
    <Grid item sm={6} md={4} xl={3} className={classes.gridItem}>
      <LinearProgress isLoading={loading} />
      <CardLink
        title={'Telemetria'}
        icon={<TelemetriaIcon />}
        action={() =>
          engecompRedirect({ setLoading, dispatch, client: false })
        }>
        Acompanhamento de medições de energia online e simultânea
      </CardLink>
    </Grid>
  )
}

export const MenuLinkEngecomp = {
  icon: <TelemetriaIcon cliente={true} />,
  text: 'Telemetria',
  action: () =>
    engecompRedirect({ setLoading: null, dispatch: null, client: true }),
}

const engecompRedirect = ({ setLoading, dispatch, client }) => {
  let credenciaisUser = null
  getCredenciais(client).then(result => {
    credenciaisUser = result
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    var requestOptions = {
      method: 'POST',
      mode: 'cors',
      headers: myHeaders,
      body: credenciaisUser,
      redirect: 'follow',
    }

    fetch(
      'https://solarhub.engecomp.io/sso/login/36386651-225b-4c6c-873f-6983ae8c6215',
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        let res = JSON.parse(result)
        if (res?.status == 'OK') {
          window.open(
            `https://solarhub.engecomp.io/?accessToken=${res.token}`,
            '_blank'
          )
        }
      })
      .catch(error => {
        if (dispatch) {
          dispatch(
            addNotification({
              key: new Date().getTime().toString(),
              message: `Problema ao fazer login, tente novamente mais tarde. ${error}`,
              options: { variant: 'error' },
            })
          )
        } else {
          alert(`Problema ao fazer login, tente novamente mais tarde. ${error}`) // eslint-disable-line no-alert
        }
      })
      .finally(() => {
        if (setLoading) setLoading(false)
      })
  })
}

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.defaultPadding,
    margin: 0,
    marginTop: theme.spacing(1.5),
    width: '100%',
  },
  gridItem: {
    width: '100% !important',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
}))
