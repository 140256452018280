import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import Actions from './utils/actions'
import toQueryString from './utils/toQueryString'

const RESOURCE_NAME = 'demandaPonto'

const endpoint = (action, id) => {
  return `/acl/pontos/${id}/demandas`
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingDemandaPontoSuccess(resource))
  return Promise.resolve(resource)
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_DEMANDA_PONTO_SUCCESS = 'LOADING_DEMANDA_PONTO_SUCCESS'
export function loadingDemandaPontoSuccess(demandaPonto) {
  return {
    type: LOADING_DEMANDA_PONTO_SUCCESS,
    demandaPonto,
  }
}

export function fetchMultipleDemandaPonto(parentId, params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(
      endpoint(Actions.INDEX, parentId) + toQueryString(params)
    ).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

const demandaPonto = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_DEMANDA_PONTO_SUCCESS: {
      const { demandaPonto: payload } = action
      return mergePayload(state, payload)
    }

    default:
      return state
  }
}

export default demandaPonto
