import React, { Component } from 'react'

import TimelineMax from 'gsap/TimelineMax'
import { Power2 } from 'gsap/TweenMax'
import StaticIcon from './StaticIcon'

const offset = 10
const dotOffset = offset * 2
const z = 1.0005

class Loader extends Component {
  constructor(props) {
    super(props)

    this.timeline = new TimelineMax({
      onComplete: function () {
        this.restart()
      },
    })
  }

  componentDidMount() {
    this.timeline
      .from(
        '#loader-dot-1',
        1.0,
        {
          scale: 0,
          opacity: 0,
          y: dotOffset,
          transformOrigin: 'center',
          ease: Power2.easeOut,
        },
        0.15
      )
      .from(
        '#loader-dot-2',
        1.0,
        {
          scale: 0,
          opacity: 0,
          x: -dotOffset,
          transformOrigin: 'center',
          ease: Power2.easeOut,
        },
        0.3
      )
      .from(
        '#loader-dot-3',
        1.0,
        {
          scale: 0,
          opacity: 0,
          y: -dotOffset,
          transformOrigin: 'center',
          ease: Power2.easeOut,
        },
        0.45
      )
      .from(
        '#loader-dot-4',
        1.0,
        {
          scale: 0,
          opacity: 0,
          x: dotOffset,
          transformOrigin: 'center',
          ease: Power2.easeOut,
        },
        0.6
      )
      .to(
        '#loader-dot-1',
        1.5,
        {
          opacity: 0,
          y: dotOffset * z,
          transformOrigin: 'center',
          ease: Power2.easeOut,
        },
        1.75
      )
      .to(
        '#loader-dot-2',
        1.5,
        {
          opacity: 0,
          x: -dotOffset * z,
          transformOrigin: 'center',
          ease: Power2.easeOut,
        },
        1.75
      )
      .to(
        '#loader-dot-3',
        1.5,
        {
          opacity: 0,
          y: -dotOffset * z,
          transformOrigin: 'center',
          ease: Power2.easeOut,
        },
        1.75
      )
      .to(
        '#loader-dot-4',
        1.5,
        {
          opacity: 0,
          x: dotOffset * z,
          transformOrigin: 'center',
          ease: Power2.easeOut,
        },
        1.75
      )
      .to(
        '#loader-dot-1',
        1.5,
        {
          scale: 9,
          y: dotOffset * z,
          transformOrigin: 'center',
          ease: Power2.easeOut,
        },
        1.75
      )
      .to(
        '#loader-dot-2',
        1.5,
        {
          scale: 9,
          x: -dotOffset * z,
          transformOrigin: 'center',
          ease: Power2.easeOut,
        },
        1.75
      )
      .to(
        '#loader-dot-3',
        1.5,
        {
          scale: 9,
          y: -dotOffset * z,
          transformOrigin: 'center',
          ease: Power2.easeOut,
        },
        1.75
      )
      .to(
        '#loader-dot-4',
        1.5,
        {
          scale: 9,
          x: dotOffset * z,
          transformOrigin: 'center',
          ease: Power2.easeOut,
        },
        1.75
      )
  }

  shouldComponentUpdate = () => false

  render = () => <StaticIcon {...this.props} />
}

export default Loader
