import React from 'react'
import { AppProviders } from 'contexts/AppProviders'
import AppRouter from './AppRouter'

const App = () => (
  <AppProviders>
    <AppRouter />
  </AppProviders>
)

export default App
