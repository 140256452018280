import instance from '../api'

export const fetchCurrentUser = async () => {
  const { data } = await instance.get(`/users/usuario_logado`)
  return data
}

export const fetchTokenRedeBasica = async () => {
  const { data } = await instance.get(`/users/token_rede_basica`)
  return data
}

export const fetchUserByEmail = async (params = {}) => {
  const { data } = await instance.get(`/users/user_by_email`, { params })
  return data
}

export const fetchUserRoles = async () => {
  const { data } = await instance.get(`/users/user_roles`)
  return data
}
