import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import Actions from './utils/actions'

const RESOURCE_NAME = 'cenarioPonto'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
    case Actions.CREATE:
      return `/acl/pontos/${id}/cenarios_ponto`

    case Actions.SHOW:
    case Actions.UPDATE:
    case Actions.DESTROY:
      return `/acl/cenarios_ponto/${id}`

    default:
      return ''
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingCenarioPontoSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingCenarioPontoSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_CENARIO_PONTO_SUCCESS = 'LOADING_CENARIO_PONTO_SUCCESS'
export function loadingCenarioPontoSuccess(cenarioPonto) {
  return {
    type: LOADING_CENARIO_PONTO_SUCCESS,
    cenarioPonto,
  }
}

export const DESTROYING_CENARIO_PONTO_SUCCESS = 'DESTROYING_CENARIO_PONTO_SUCCESS'
export function destroyingCenarioPontoSuccess(cenarioPontoId) {
  return {
    type: DESTROYING_CENARIO_PONTO_SUCCESS,
    cenarioPontoId,
  }
}

export function fetchMultipleCenarioPonto(parentId) {
  return async function(dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.INDEX, parentId)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchCenarioPonto(id) {
  return async function(dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.SHOW, id)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createCenarioPonto(parentId, cenarioPonto) {
  return async function(dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.CREATE, parentId), 'POST', cenarioPonto).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateCenarioPonto(id, cenarioPonto) {
  return async function(dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.UPDATE, id), 'PUT', cenarioPonto).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyCenarioPonto(id) {
  return async function(dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(endpoint(Actions.DESTROY, id), 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

export function importCenarioPonto(multipleCenarioPonto) {
  return async function(dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('import'), 'POST', multipleCenarioPonto).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

const cenarioPonto = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_CENARIO_PONTO_SUCCESS: {
      const { cenarioPonto: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_CENARIO_PONTO_SUCCESS:
      return state.delete(action.cenarioPontoId.toString())

    default:
      return state
  }
}

export default cenarioPonto
