import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles, lighten } from '@material-ui/core/styles'
import { SnackbarProvider as NotistackProvider } from 'notistack'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Notifier from 'containers/Notifier/Notifier'

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: lighten(theme.colors.green.light, 0.8),
    color: theme.colors.green.main,
    '& button': { color: 'inherit' },
  },
  error: {
    backgroundColor: lighten(theme.colors.red.light, 0.8),
    color: theme.colors.red.main,
    '& button': { color: 'inherit' },
  },
  warning: {
    backgroundColor: lighten(theme.colors.yellow.light, 0.8),
    color: theme.colors.yellow.main,
    '& button': { color: 'inherit' },
  },
  info: {
    backgroundColor: lighten(theme.colors.blue.light, 0.8),
    color: theme.colors.blue.main,
    '& button': { color: 'inherit' },
  },
}))

const SnackbarProvider = ({ children }) => {
  const notistackRef = React.createRef()
  const onClickDismiss = key => () => notistackRef.current.closeSnackbar(key)
  const classes = useStyles()

  return (
    <NotistackProvider
      preventDuplicate
      ref={notistackRef}
      maxSnack={3}
      action={key => (
        <IconButton onClick={onClickDismiss(key)} color='default' size='small'>
          <CloseIcon />
        </IconButton>
      )}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}>
      <>
        <Notifier />
        {children}
      </>
    </NotistackProvider>
  )
}

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SnackbarProvider
