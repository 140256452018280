import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import CardLink from '../../CardLink/CardLink'
import ListAltIcon from '@material-ui/icons/ListAlt'
import { fetchTokenRedeBasica } from 'api/clients/users'

const redeBasicaRedirect = async () => {
  const token = await fetchTokenRedeBasica()

  let link = token.keyToken
    ? `http://redebasica.bestdeal.com.br/login/${token.keyToken}`
    : 'http://redebasica.bestdeal.com.br/login'

  window.open(link, '_blank').focus()
}

export const FaturasThymos = () => {
  const classes = useStyles()

  return (
    <Grid item sm={6} md={4} xl={3} className={classes.gridItem}>
      <CardLink
        title={'Rede Básica'}
        icon={<ListAltIcon />}
        action={redeBasicaRedirect}>
        Faturas - Rede Básica
      </CardLink>
    </Grid>
  )
}

export const MenuLinkFaturasThymos = {
  icon: <ListAltIcon />,
  text: 'Rede Básica',
  action: redeBasicaRedirect,
}

const useStyles = makeStyles(theme => ({
  gridItem: {
    width: '100% !important',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
}))
