import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import * as reducers from '../redux/modules'
import { reducer as formReducer } from 'redux-form'

const rootReducer = (state, action) =>
  combineReducers({
    ...reducers,
    form: formReducer,
  })(state, action)

const middleware = [
  applyMiddleware(thunk),
  ...(window.__REDUX_DEVTOOLS_EXTENSION__
    ? [window.__REDUX_DEVTOOLS_EXTENSION__()]
    : []),
]

const store = createStore(rootReducer, compose(...middleware))

export default store
