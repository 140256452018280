import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  KBarProvider,
  KBarPortal,
  KBarPositioner,
  KBarAnimator,
  KBarSearch,
  KBarResults,
  useMatches,
} from 'kbar'

import { useChangeTheme } from 'contexts/ThemeContext'
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent'
import { clientItems, items } from '../components/Layout/Items'
import { useRoles, useUser } from 'hooks/useSession'

export const KBarContext = ({ children }) => {
  const classes = useStyles()
  const changeTheme = useChangeTheme()
  const roles = useRoles()
  const isClient = React.useMemo(() => roles.includes('cliente'), [roles])
  const user = useUser()
  const habilitarTesteFeature = user?.habilitar_teste_feature || false

  /* Remover menus que estão em teste se usuário não estiver habiltiado */
  const menuItens = React.useMemo(() => {
    const itemList = isClient ? clientItems : items

    return habilitarTesteFeature
      ? tranformDados(itemList)
      : tranformDados(itemList.filter(item => !item.test))
  }, [isClient, habilitarTesteFeature])

  let actions = menuItens

  const handleTogglePaletteType = React.useCallback(() => {
    const paletteType =
      document.cookie.split('paletteType=')[1].split(';')[0] === 'light'
        ? 'dark'
        : 'light'

    changeTheme({ paletteType })
  }, [changeTheme])

  if (window.location.pathname == '/session/new') {
    actions = []
  }

  actions.push({
    id: 'tema',
    name: 'Trocar a cor do tema',
    keywords: 'tema',
    icon: <WbIncandescentIcon />,
    perform: () => handleTogglePaletteType(),
  })

  return (
    <KBarProvider actions={actions}>
      <KBarPortal>
        <KBarPositioner className={classes.position}>
          <KBarAnimator className={classes.animatorStyle}>
            <KBarSearch
              className={classes.searchStyle}
              defaultPlaceholder='Digite para onde você que ir...'
            />
            <RenderResults />
          </KBarAnimator>
        </KBarPositioner>
      </KBarPortal>
      {children}
    </KBarProvider>
  )
}

KBarContext.propTypes = {
  children: PropTypes.node.isRequired,
}

function RenderResults() {
  const { results, rootActionId } = useMatches()
  const classes = useStyles()

  const roles = useRoles()
  const isClient = React.useMemo(() => roles.includes('cliente'), [roles])

  let filtered = isClient ? filtraItensCliente(results) : results

  return (
    <KBarResults
      items={filtered}
      onRender={({ item, active }) =>
        typeof item === 'string' ? (
          <div className={classes.groupNameStyle}>{item}</div>
        ) : (
          <ResultItem
            action={item}
            active={active ? active : false}
            currentRootActionId={rootActionId}
          />
        )
      }
    />
  )
}

const ResultItem = React.forwardRef(
  ({ action, active, currentRootActionId }, ref) => {
    const ancestors = React.useMemo(() => {
      if (!currentRootActionId) return action.ancestors
      const index = action.ancestors.findIndex(
        ancestor => ancestor.id === currentRootActionId
      )
      // +1 removes the currentRootAction; e.g.
      // if we are on the "Set theme" parent action,
      // the UI should not display "Set theme… > Dark"
      // but rather just "Dark"
      return action.ancestors.slice(index + 1)
    }, [action.ancestors, currentRootActionId])

    const theme = useTheme()

    return (
      <div
        ref={ref}
        style={{
          padding: '12px 16px',
          background: active ? 'rgba(0 0 0 / 0.05)' : 'transparent',
          borderLeft: `2px solid ${
            active ? theme.palette.primary.main : 'transparent'
          }`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}>
        <div
          style={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            fontSize: 14,
          }}>
          {action.icon && action.icon}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              {ancestors.length > 0 &&
                ancestors.map(ancestor => (
                  <React.Fragment key={ancestor.id}>
                    <span
                      style={{
                        opacity: 0.5,
                        marginRight: 8,
                      }}>
                      {ancestor.name}
                    </span>
                    <span
                      style={{
                        marginRight: 8,
                      }}>
                      &rsaquo;
                    </span>
                  </React.Fragment>
                ))}
              <span>{action.name}</span>
            </div>
            {action.subtitle && (
              <span style={{ fontSize: 12 }}>{action.subtitle}</span>
            )}
          </div>
        </div>
        {action.shortcut?.length ? (
          <div
            aria-hidden
            style={{ display: 'grid', gridAutoFlow: 'column', gap: '4px' }}>
            {action.shortcut.map(sc => (
              <kbd
                key={sc}
                style={{
                  padding: '4px 6px',
                  background: 'rgba(0 0 0 / .1)',
                  borderRadius: '4px',
                  fontSize: 14,
                }}>
                {sc}
              </kbd>
            ))}
          </div>
        ) : null}
      </div>
    )
  }
)

ResultItem.propTypes = {
  action: PropTypes.object,
  active: PropTypes.boolean,
  currentRootActionId: PropTypes.object,
}

const filtraItensCliente = results =>
  results.filter(val =>
    val.id == 'tema' ? val : clientItems.find(cli => cli.text == val.id)
  )

const tranformDados = dados =>
  dados
    .map(v => {
      if (v.text == 'Sair') return null
      return {
        ...v,
        id: v.text,
        name: v.text,
        keywords: v.text,
        perform: () => (window.location.pathname = v.path),
      }
    })
    .filter(v => v)

const useStyles = makeStyles(theme => {
  return {
    animatorStyle: {
      maxWidth: '600px',
      width: '100%',
      background: theme.colors.blueGray.main,
      color: theme.palette.getContrastText(theme.palette.background.default),
      borderRadius: '8px',
      overflow: 'hidden',
      boxShadow: '0px 6px 20px rgb(0 0 0 / 20%)', // theme shadow
    },
    searchStyle: {
      padding: '12px 16px',
      fontSize: '16px',
      width: '100%',
      boxSizing: 'border-box',
      outline: 'none',
      border: 'none',
      background: theme.colors.blueGray.main,
      color: theme.palette.getContrastText(theme.palette.background.default),
    },
    groupNameStyle: {
      padding: '8px 16px',
      fontSize: '10px',
      textTransform: 'uppercase',
      opacity: 0.5,
    },
    position: {
      zIndex: '2',
      position: 'sticky',
    },
  }
})
