import React from 'react'
import PropTypes from 'prop-types'

import { MuiPickersUtilsProvider as PickersProvider } from '@material-ui/pickers'
import { AppProvider } from 'contexts/AppContext'
import { SessionProvider } from 'contexts/SessionContext'
import SnackbarProvider from 'contexts/SnackbarProvider'
import { ThemeProvider } from 'contexts/ThemeContext'

import DateFnsUtils from '@date-io/date-fns'
import locale from 'date-fns/esm/locale/pt-BR'
import JavascriptTimeAgo from 'javascript-time-ago'
import pt from 'javascript-time-ago/locale/pt'

import ErrorBoundary from '@honeybadger-io/react'
import instance from 'api/api'
import { Provider } from 'react-redux'
import HoneybadgerConfig from 'utils/HoneybadgerConfig'
import NetworkService from 'utils/NetworkService'
import VersionChecker from 'utils/VersionChecker'
import store from 'utils/store'
import { KBarContext } from './KBarContext'
import { QueryClientProvider } from './QueryClientProvider'

JavascriptTimeAgo.locale(pt)

NetworkService.setupInterceptors(store)
NetworkService.setupInterceptors(store, instance)

export const AppProviders = ({ children }) => (
  <ErrorBoundary honeybadger={HoneybadgerConfig}>
    <QueryClientProvider>
      <Provider store={store}>
        <SessionProvider>
          <AppProvider>
            <ThemeProvider>
              <SnackbarProvider>
                <PickersProvider utils={DateFnsUtils} locale={locale}>
                  <KBarContext>{children}</KBarContext>
                </PickersProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </AppProvider>
        </SessionProvider>

        <VersionChecker />
      </Provider>
    </QueryClientProvider>
  </ErrorBoundary>
)

AppProviders.propTypes = {
  children: PropTypes.node,
}
