import { Map } from 'immutable'

export const UPDATE_FILTER = 'UPDATE_FILTER'
export function updateFilter(collection, filter) {
  return {
    type: UPDATE_FILTER,
    collection,
    filter,
  }
}
const initialState = Map({})

export default function filter(state = initialState, action) {
  switch (action.type) {
    case UPDATE_FILTER: {
      const { collection, filter } = action

      return state.mergeDeep({
        [collection]: filter,
      })
    }

    default:
      return state
  }
}
