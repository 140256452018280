import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import Actions from './utils/actions'

const RESOURCE_NAME = 'medicao'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
    case Actions.CREATE:
      return `/acl/pontos/${id}/medidas_mensais`

    case Actions.SHOW:
    case Actions.UPDATE:
    case Actions.DESTROY:
      return `/acl/medidas_mensais/${id}`

    case 'import':
      return '/acl/medidas_mensais/import'

    default:
      return ''
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingMedicaoSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingMedicaoSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_MEDICAO_SUCCESS = 'LOADING_MEDICAO_SUCCESS'
export function loadingMedicaoSuccess(medicao) {
  return {
    type: LOADING_MEDICAO_SUCCESS,
    medicao,
  }
}

export const DESTROYING_MEDICAO_SUCCESS = 'DESTROYING_MEDICAO_SUCCESS'
export function destroyingMedicaoSuccess(medicaoId) {
  return {
    type: DESTROYING_MEDICAO_SUCCESS,
    medicaoId,
  }
}

export function fetchMultipleMedicao(parentId) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.INDEX, parentId)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchMedicao(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.SHOW, id)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createMedicao(parentId, medicao) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.CREATE, parentId), 'POST', medicao).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateMedicao(id, medicao) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.UPDATE, id), 'PUT', medicao).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyMedicao(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(endpoint(Actions.DESTROY, id), 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

export function importMedicao(multipleMedicao) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('import'), 'POST', multipleMedicao).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

const medicao = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_MEDICAO_SUCCESS: {
      const { medicao: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_MEDICAO_SUCCESS:
      return state.delete(action.medicaoId.toString())

    default:
      return state
  }
}

export default medicao
