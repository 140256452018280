import get from 'lodash.get'

export const isAllowed = (user, permissions) => {
  return permissions.some(permission =>
    get(user, `permissions.${permission}`, false)
  )
}

export const hasRole = (user, roles) =>
  roles.some(role => get(user, 'roles', []).includes(role))
