import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import toQueryString from './utils/toQueryString'

const RESOURCE_NAME = 'dashboard'
const ENDPOINT = '/acl/dashboard'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingDashboardSuccess(resource))
  return Promise.resolve(resource)
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_DASHBOARD_SUCCESS = 'LOADING_DASHBOARD_SUCCESS'
export function loadingDashboardSuccess(dashboard) {
  return {
    type: LOADING_DASHBOARD_SUCCESS,
    dashboard,
  }
}

export function fetchDashboard(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT + toQueryString(params)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchDashboardPerfisSemFechamento(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(
      `${ENDPOINT}/perfis_sem_fechamento${toQueryString(params)}`
    ).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchDashboardPerfisSemPedagio(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(
      `${ENDPOINT}/perfis_sem_pedagio${toQueryString(params)}`
    ).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

const dashboard = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_DASHBOARD_SUCCESS: {
      const { dashboard: payload } = action
      return state.merge(payload)
    }

    default:
      return state
  }
}

export default dashboard
