import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import toQueryString from './utils/toQueryString'

const RESOURCE_NAME = 'fechamento'
const ENDPOINT = '/acl/fechamentos'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingFechamentoSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingFechamentoSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_FECHAMENTO_SUCCESS = 'LOADING_FECHAMENTO_SUCCESS'
export function loadingFechamentoSuccess(fechamento) {
  return {
    type: LOADING_FECHAMENTO_SUCCESS,
    fechamento,
  }
}

export const DESTROYING_FECHAMENTO_SUCCESS = 'DESTROYING_FECHAMENTO_SUCCESS'
export function destroyingFechamentoSuccess(fechamentoId) {
  return {
    type: DESTROYING_FECHAMENTO_SUCCESS,
    fechamentoId,
  }
}

export function fetchMultipleFechamento(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT + toQueryString(params)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchFechamento(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createFechamento(fechamento) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT, 'POST', fechamento).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateFechamento(id, fechamento) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`, 'PUT', fechamento).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyFechamento(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(`${ENDPOINT}/${id}`, 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

export function assignFechamento(id, userId) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}/assign/${userId}`, 'POST').then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

const fechamento = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_FECHAMENTO_SUCCESS: {
      const { fechamento: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_FECHAMENTO_SUCCESS:
      return state.delete(action.fechamentoId.toString())

    default:
      return state
  }
}

export default fechamento
