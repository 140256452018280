import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import Actions from './utils/actions'

const RESOURCE_NAME = 'historicoEmailTransacional'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
      return `/acl/pontos/${id}/historico_emails_transacionais`

    case Actions.SHOW:
      return `/acl/historico_emails_transacionais/${id}`

    default:
      return ''
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingHistoricoEmailTransacionalSuccess(resource))
  return Promise.resolve(resource)
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_HISTORICO_SUCCESS = 'LOADING_HISTORICO_SUCCESS'
export function loadingHistoricoEmailTransacionalSuccess(
  historicoEmailTransacional
) {
  return {
    type: LOADING_HISTORICO_SUCCESS,
    historicoEmailTransacional,
  }
}

export function fetchMultipleHistoricoEmailTransacional(parentId, data) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(
      `${endpoint(Actions.INDEX, parentId)}?data=${data ? data : ''}`
    ).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchHistoricoEmailTransacional(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.SHOW, id)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

const historicoEmailTransacional = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_HISTORICO_SUCCESS: {
      const { historicoEmailTransacional: payload } = action
      return mergePayload(state, payload)
    }

    default:
      return state
  }
}

export default historicoEmailTransacional
