import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import Actions from './utils/actions'

const RESOURCE_NAME = 'sazonalizacao'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
    case Actions.CREATE:
      return `/acl/contratos/${id}/sazonalizacoes`

    case Actions.SHOW:
    case Actions.UPDATE:
    case Actions.DESTROY:
      return `/acl/sazonalizacoes/${id}`

    default:
      return ''
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingSazonalizacaoSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingSazonalizacaoSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_SAZONALIZACAO_SUCCESS = 'LOADING_SAZONALIZACAO_SUCCESS'
export function loadingSazonalizacaoSuccess(sazonalizacao) {
  return {
    type: LOADING_SAZONALIZACAO_SUCCESS,
    sazonalizacao,
  }
}

export const DESTROYING_SAZONALIZACAO_SUCCESS =
  'DESTROYING_SAZONALIZACAO_SUCCESS'
export function destroyingSazonalizacaoSuccess(sazonalizacaoId) {
  return {
    type: DESTROYING_SAZONALIZACAO_SUCCESS,
    sazonalizacaoId,
  }
}

export function fetchMultipleSazonalizacao(parentId) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.INDEX, parentId)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchSazonalizacao(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.SHOW, id)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createSazonalizacao(parentId, sazonalizacao) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(
      endpoint(Actions.CREATE, parentId),
      'POST',
      sazonalizacao
    ).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateSazonalizacao(id, sazonalizacao) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.UPDATE, id), 'PUT', sazonalizacao).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroySazonalizacao(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(endpoint(Actions.DESTROY, id), 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const sazonalizacao = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_SAZONALIZACAO_SUCCESS: {
      const { sazonalizacao: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_SAZONALIZACAO_SUCCESS:
      return state.delete(action.sazonalizacaoId.toString())

    default:
      return state
  }
}

export default sazonalizacao
