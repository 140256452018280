import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: 'calc(100vh - 120px)',
  },
  svg: {
    margin: '0 auto',
    height: 100,
    width: 100,
  },
  loaderBlue: {
    fill: theme.logo.blue,
  },
  loaderPurple: {
    fill: theme.logo.purple,
  },
  loaderYellow: {
    fill: theme.logo.yellow,
  },
  loaderGreen: {
    fill: theme.logo.green,
  },
  logoElectric: {
    fill: theme.logo.color,
  },
  loaderOrange: {
    fill: '#F47835',
  },
}))

const REACT_APP_COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME || 'Electric'

const StaticIcon = ({ className }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {REACT_APP_COMPANY_NAME == 'Electric' && (
        <ElectricLoader className={className} />
      )}
      {REACT_APP_COMPANY_NAME == 'Thymos' && (
        <ThymosLoader className={className} />
      )}
      {(REACT_APP_COMPANY_NAME == 'eGrid' ||
        REACT_APP_COMPANY_NAME == 'Coprel') && (
        <DemoLoader className={className} />
      )}
    </div>
  )
}

StaticIcon.propTypes = {
  className: PropTypes.string,
}

const ElectricLoader = ({ className }) => {
  const classes = useStyles()
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 70 70'
      className={classNames(className, classes.svg)}>
      <title>eGrid | {REACT_APP_COMPANY_NAME} </title>
      <circle
        id='loader-dot-1'
        className={classNames(classes.loaderPurple, 'dot')}
        cx='34.56'
        cy='16.83'
        r='3.7'
      />
      <circle
        id='loader-dot-2'
        className={classNames(classes.loaderGreen, 'dot')}
        cx='51.87'
        cy='34.4'
        r='3.7'
      />
      <circle
        id='loader-dot-3'
        className={classNames(classes.loaderYellow, 'dot')}
        cx='34.3'
        cy='51.71'
        r='3.7'
      />
      <circle
        id='loader-dot-4'
        className={classNames(classes.loaderBlue, 'dot')}
        cx='17'
        cy='34.14'
        r='3.7'
      />
    </svg>
  )
}

ElectricLoader.propTypes = {
  className: PropTypes.string,
}

const ThymosLoader = ({ className }) => {
  const classes = useStyles()
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 70 70'
      className={classNames(className, classes.svg)}>
      <title>eGrid | {REACT_APP_COMPANY_NAME} </title>
      <circle
        id='loader-dot-1'
        className={classNames(classes.loaderOrange, 'dot')}
        cx='34.56'
        cy='16.83'
        r='3.7'
      />
      <circle
        id='loader-dot-2'
        className={classNames(classes.loaderOrange, 'dot')}
        cx='51.87'
        cy='34.4'
        r='3.7'
      />
      <circle
        id='loader-dot-3'
        className={classNames(classes.loaderOrange, 'dot')}
        cx='34.3'
        cy='51.71'
        r='3.7'
      />
      <circle
        id='loader-dot-4'
        className={classNames(classes.loaderOrange, 'dot')}
        cx='17'
        cy='34.14'
        r='3.7'
      />
    </svg>
  )
}

ThymosLoader.propTypes = {
  className: PropTypes.string,
}

const DemoLoader = ({ className }) => {
  const classes = useStyles()
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 70 70'
      className={classNames(className, classes.svg)}>
      <title>eGrid | {REACT_APP_COMPANY_NAME} </title>
      <circle
        id='loader-dot-1'
        className={classNames(classes.loaderBlue, 'dot')}
        cx='34.56'
        cy='16.83'
        r='3.7'
      />
      <circle
        id='loader-dot-2'
        className={classNames(classes.loaderBlue, 'dot')}
        cx='51.87'
        cy='34.4'
        r='3.7'
      />
      <circle
        id='loader-dot-3'
        className={classNames(classes.loaderBlue, 'dot')}
        cx='34.3'
        cy='51.71'
        r='3.7'
      />
      <circle
        id='loader-dot-4'
        className={classNames(classes.loaderBlue, 'dot')}
        cx='17'
        cy='34.14'
        r='3.7'
      />
    </svg>
  )
}

DemoLoader.propTypes = {
  className: PropTypes.string,
}

export default StaticIcon
