import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import toQueryString from './utils/toQueryString'

const RESOURCE_NAME = 'concessionaria'
const ENDPOINT = '/concessionarias'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingConcessionariaSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingConcessionariaSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_CONCESSIONARIA_SUCCESS = 'LOADING_CONCESSIONARIA_SUCCESS'
export function loadingConcessionariaSuccess(concessionaria) {
  return {
    type: LOADING_CONCESSIONARIA_SUCCESS,
    concessionaria,
  }
}

export const DESTROYING_CONCESSIONARIA_SUCCESS = 'DESTROYING_CONCESSIONARIA_SUCCESS'
export function destroyingConcessionariaSuccess(concessionariaId) {
  return {
    type: DESTROYING_CONCESSIONARIA_SUCCESS,
    concessionariaId,
  }
}

export function fetchMultipleConcessionaria(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT + toQueryString(params)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchConcessionaria(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createConcessionaria(concessionaria) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT, 'POST', concessionaria).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateConcessionaria(id, concessionaria) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`, 'PUT', concessionaria).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyConcessionaria(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(`${ENDPOINT}/${id}`, 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const concessionaria = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_CONCESSIONARIA_SUCCESS: {
      const { concessionaria: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_CONCESSIONARIA_SUCCESS:
      return state.delete(action.concessionariaId.toString())

    default:
      return state
  }
}

export default concessionaria
