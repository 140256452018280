import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import toQueryString from './utils/toQueryString'

const RESOURCE_NAME = 'cotacao'
const ENDPOINT = '/acl/cotacoes'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingCotacaoSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingCotacaoSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_COTACAO_SUCCESS = 'LOADING_COTACAO_SUCCESS'
export function loadingCotacaoSuccess(cotacao) {
  return {
    type: LOADING_COTACAO_SUCCESS,
    cotacao,
  }
}

export function fetchMultipleCotacoes(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT + toQueryString(params)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchCotacao(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export const DESTROYING_COTACAO_SUCCESS = 'DESTROYING_COTACAO_SUCCESS'
export function destroyingCotacaoSuccess(cotacaoId) {
  return {
    type: DESTROYING_COTACAO_SUCCESS,
    cotacaoId,
  }
}

export function createCotacao(cotacao) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT, 'POST', cotacao).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateCotacao(id, cotacao) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`, 'PUT', cotacao).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyCotacao(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(`${ENDPOINT}/${id}`, 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

export function importCotacao(multipleCotacao) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/import`, 'POST', multipleCotacao).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function exportMultipleCotacao(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.callWithResponse(
      `/acl/cotacoes/export${toQueryString(params)}`
    ).then(
      resource => {
        dispatch(loadingSuccess(RESOURCE_NAME))
        return Promise.resolve(resource)
      },
      error => reject(dispatch, error)
    )
  }
}

const cotacao = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_COTACAO_SUCCESS: {
      const { cotacao: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_COTACAO_SUCCESS:
      return state.delete(action.cotacaoId.toString())

    default:
      return state
  }
}

export default cotacao
