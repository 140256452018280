import { Map, fromJS } from 'immutable'

export const LOAD_IMPORT_DATA = 'LOAD_IMPORT_DATA'
export function loadData(collection, data) {
  return {
    type: LOAD_IMPORT_DATA,
    collection,
    data,
  }
}

export const CLEAR_IMPORT_DATA = 'CLEAR_IMPORT_DATA'
export function clearData(collection) {
  return {
    type: CLEAR_IMPORT_DATA,
    collection,
  }
}

const structure = {
  data: '',
}

const initialState = Map({})

export default function dataImporter(state = initialState, action) {
  switch (action.type) {
    case LOAD_IMPORT_DATA: {
      const { collection, data } = action
      if (!state.has(collection))
        state = state.set(collection, fromJS(structure))
      return state.setIn([collection, 'data'], data)
    }

    case CLEAR_IMPORT_DATA: {
      const { collection } = action
      return state.delete(collection)
    }

    default:
      return state
  }
}
