import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import Actions from './utils/actions'

const RESOURCE_NAME = 'laudo'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
    case Actions.CREATE:
      return `/acl/pontos/${id}/laudos`

    case Actions.SHOW:
    case Actions.UPDATE:
    case Actions.DESTROY:
      return `/acl/laudos/${id}`

    default:
      return ''
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingLaudoSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingLaudoSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_LAUDO_SUCCESS = 'LOADING_LAUDO_SUCCESS'
export function loadingLaudoSuccess(laudo) {
  return {
    type: LOADING_LAUDO_SUCCESS,
    laudo,
  }
}

export const DESTROYING_LAUDO_SUCCESS = 'DESTROYING_LAUDO_SUCCESS'
export function destroyingLaudoSuccess(laudoId) {
  return {
    type: DESTROYING_LAUDO_SUCCESS,
    laudoId,
  }
}

export function fetchMultipleLaudo(parentId) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.INDEX, parentId)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchLaudo(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.SHOW, id)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createLaudo(parentId, laudo) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.CREATE, parentId), 'POST', laudo).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateLaudo(id, laudo) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.UPDATE, id), 'PUT', laudo).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyLaudo(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(endpoint(Actions.DESTROY, id), 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

export function importLaudo(multipleLaudo) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('import'), 'POST', multipleLaudo).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

const laudo = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_LAUDO_SUCCESS: {
      const { laudo: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_LAUDO_SUCCESS:
      return state.delete(action.laudoId.toString())

    default:
      return state
  }
}

export default laudo
