export const addNotification = notification => ({
  type: 'ADD_NOTIFICATION',
  notification: notification,
})

export const removeNotification = key => ({
  type: 'REMOVE_NOTIFICATION',
  key: key,
})

const defaultState = {
  notifications: [],
}

const defaultOptions = {
  variant: 'info',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
    autoHideDuration: 500,
  },
}

export default function notifier(state = defaultState, action) {
  switch (action.type) {
    case 'ADD_NOTIFICATION': {
      const { options, ...rest } = action.notification

      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...rest,
            options: { ...defaultOptions, ...options },
          },
        ],
      }
    }

    case 'REMOVE_NOTIFICATION':
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key
        ),
      }

    default:
      return state
  }
}
