import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import toQueryString from './utils/toQueryString'
import Actions from './utils/actions'

const RESOURCE_NAME = 'consumidor'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
      return '/acl/consumidores'

    case Actions.CREATE:
      return `/acl/contratos/${id}/consumidores`

    case Actions.SHOW:
    case Actions.UPDATE:
    case Actions.DESTROY:
      return `/acl/consumidores/${id}`

    default:
      return ''
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingConsumidorSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingConsumidorSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_CONSUMIDOR_SUCCESS = 'LOADING_CONSUMIDOR_SUCCESS'
export function loadingConsumidorSuccess(consumidor) {
  return {
    type: LOADING_CONSUMIDOR_SUCCESS,
    consumidor,
  }
}

export const DESTROYING_CONSUMIDOR_SUCCESS = 'DESTROYING_CONSUMIDOR_SUCCESS'
export function destroyingConsumidorSuccess(consumidorId) {
  return {
    type: DESTROYING_CONSUMIDOR_SUCCESS,
    consumidorId,
  }
}

export function fetchMultipleConsumidor(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.INDEX) + toQueryString(params)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchConsumidor(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.SHOW, id)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createConsumidor(parentId, consumidor) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(
      endpoint(Actions.CREATE, parentId),
      'POST',
      consumidor
    ).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateConsumidor(id, consumidor) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.UPDATE, id), 'PUT', consumidor).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyConsumidor(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(endpoint(Actions.DESTROY, id), 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const consumidor = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_CONSUMIDOR_SUCCESS: {
      const { consumidor: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_CONSUMIDOR_SUCCESS:
      return state.delete(action.consumidorId.toString())

    default:
      return state
  }
}

export default consumidor
