import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'

const RESOURCE_NAME = 'tipoEnergia'
const ENDPOINT = '/acl/tipos_energia'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingTipoEnergiaSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingTipoEnergiaSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_TIPO_ENERGIA_SUCCESS = 'LOADING_TIPO_ENERGIA_SUCCESS'
export function loadingTipoEnergiaSuccess(tipoEnergia) {
  return {
    type: LOADING_TIPO_ENERGIA_SUCCESS,
    tipoEnergia,
  }
}

export const DESTROYING_TIPO_ENERGIA_SUCCESS = 'DESTROYING_TIPO_ENERGIA_SUCCESS'
export function destroyingTipoEnergiaSuccess(tipoEnergiaId) {
  return {
    type: DESTROYING_TIPO_ENERGIA_SUCCESS,
    tipoEnergiaId,
  }
}

export function fetchMultipleTipoEnergia() {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchTipoEnergia(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createTipoEnergia(tipoEnergia) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT, 'POST', tipoEnergia).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateTipoEnergia(id, tipoEnergia) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`, 'PUT', tipoEnergia).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyTipoEnergia(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(`${ENDPOINT}/${id}`, 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const tipoEnergia = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_TIPO_ENERGIA_SUCCESS: {
      const { tipoEnergia: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_TIPO_ENERGIA_SUCCESS:
      return state.delete(action.tipoEnergiaId.toString())

    default:
      return state
  }
}

export default tipoEnergia
