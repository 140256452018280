import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import toQueryString from './utils/toQueryString'
import Actions from './utils/actions'

const RESOURCE_NAME = 'operacao'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
      return '/acl/operacoes'

    case Actions.CREATE:
      return `/acl/fechamentos_perfis/${id}/operacoes`

    case Actions.SHOW:
    case Actions.UPDATE:
    case Actions.DESTROY:
      return `/acl/operacoes/${id}`

    default:
      return `/acl/operacoes/${id}/${action}`
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingOperacaoSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingOperacaoSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_OPERACAO_SUCCESS = 'LOADING_OPERACAO_SUCCESS'
export function loadingOperacaoSuccess(operacao) {
  return {
    type: LOADING_OPERACAO_SUCCESS,
    operacao,
  }
}

export const DESTROYING_OPERACAO_SUCCESS = 'DESTROYING_OPERACAO_SUCCESS'
export function destroyingOperacaoSuccess(operacaoId) {
  return {
    type: DESTROYING_OPERACAO_SUCCESS,
    operacaoId,
  }
}

export function fetchOperacao(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.SHOW, id)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchMultipleOperacao(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.INDEX) + toQueryString(params)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function downloadMultipleOperacao(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.callWithResponse(
      `/acl/operacoes/download${toQueryString(params)}`
    ).then(
      resource => {
        dispatch(loadingSuccess(RESOURCE_NAME))
        return Promise.resolve(resource)
      },
      error => reject(dispatch, error)
    )
  }
}

export function createOperacao(parentId, operacao) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.CREATE, parentId), 'POST', operacao).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateOperacao(id, operacao) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.UPDATE, id), 'PUT', operacao).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyOperacao(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(endpoint(Actions.DESTROY, id), 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

export function cancelOperacao(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('cancel', id), 'POST').then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function confirmOperacao(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('confirm', id), 'POST').then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function finishOperacao(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('finish', id), 'POST').then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function registerOperacao(id, operacao) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('register', id), 'POST', operacao).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function assignOperacao(id, userId) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${endpoint('assign', id)}/${userId}`, 'POST').then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

const operacao = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_OPERACAO_SUCCESS: {
      const { operacao: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_OPERACAO_SUCCESS:
      return state.delete(action.operacaoId.toString())

    default:
      return state
  }
}

export default operacao
