import AsyncLoader from 'components/Loader/AsyncLoader'
import Loadable from 'react-loadable'

const Session = Loadable({
  loader: () => import('containers/Session/SessionRouter'),
  loading: AsyncLoader,
})

const Password = Loadable({
  loader: () => import('containers/Password/PasswordRouter'),
  loading: AsyncLoader,
})

const Main = Loadable({
  loader: () => import('components/Layout/Layout'),
  loading: AsyncLoader,
})

const Share = Loadable({
  loader: () => import('components/Share/Share'),
  loading: AsyncLoader,
})

const Dashboard = Loadable({
  loader: () => import('components/Dashboard/Dashboard'),
  loading: AsyncLoader,
})

const ConfiguracoesContainer = Loadable({
  loader: () =>
    import('containers/ConfiguracoesContainer/ConfiguracoesContainer'),
  loading: AsyncLoader,
})

const Arquivos = Loadable({
  loader: () => import('containers/Arquivos'),
  loading: AsyncLoader,
})

const AgentesContainer = Loadable({
  loader: () => import('containers/AgentesContainer/AgentesContainer'),
  loading: AsyncLoader,
})

const ConcessionariaRouter = Loadable({
  loader: () => import('containers/Concessionaria/ConcessionariaRouter'),
  loading: AsyncLoader,
})

const ContratoRouter = Loadable({
  loader: () => import('containers/Contrato/ContratoRouter'),
  loading: AsyncLoader,
})

const CliqcceeRouter = Loadable({
  loader: () => import('components/CliqCcee/CliqCcee'),
  loading: AsyncLoader,
})

const ValidacaoContratosRouter = Loadable({
  loader: () => import('components/ValidacaoContratos/ValidacaoContratos'),
  loading: AsyncLoader,
})

const ErrorContainer = Loadable({
  loader: () => import('containers/ErrorContainer/ErrorContainer'),
  loading: AsyncLoader,
})

const FechamentoRouter = Loadable({
  loader: () => import('containers/Fechamento/FechamentoRouter'),
  loading: AsyncLoader,
})

const PldRouter = Loadable({
  loader: () => import('containers/Pld/PldRouter'),
  loading: AsyncLoader,
})

const CotacaoRouter = Loadable({
  loader: () => import('containers/Cotacao/CotacaoRouter'),
  loading: AsyncLoader,
})

const OperacaoRouter = Loadable({
  loader: () => import('containers/Operacao/OperacaoRouter'),
  loading: AsyncLoader,
})

const PedagioRouter = Loadable({
  loader: () => import('containers/Pedagio/PedagioRouter'),
  loading: AsyncLoader,
})

const RelatorioRouter = Loadable({
  loader: () => import('containers/Relatorio/RelatorioRouter'),
  loading: AsyncLoader,
})

const RelatorioConsolidadoRouter = Loadable({
  loader: () =>
    import('containers/RelatorioConsolidado/RelatorioConsolidadoRouter'),
  loading: AsyncLoader,
})

const PreferenciasContainer = Loadable({
  loader: () =>
    import('containers/PreferenciasContainer/PreferenciasContainer'),
  loading: AsyncLoader,
})

const FaturamentoRouter = Loadable({
  loader: () => import('containers/Faturamento/FaturamentoRouter'),
  loading: AsyncLoader,
})

const RateioRouter = Loadable({
  loader: () => import('containers/Rateio/RateioRouter'),
  loading: AsyncLoader,
})

const MedicoesContainer = Loadable({
  loader: () => import('containers/Medicoes/MedicoesContainer'),
  loading: AsyncLoader,
})

const PainelEnergiaContainer = Loadable({
  loader: () => import('containers/PainelEnergia/PainelEnergiaContainer'),
  loading: AsyncLoader,
})

const PerfilUsuario = Loadable({
  loader: () => import('containers/PerfilUsuario'),
  loading: AsyncLoader,
})

const ProjecoesRouter = Loadable({
  loader: () => import('containers/Projecao/ProjecaoRouter'),
  loading: AsyncLoader,
})

const EmailCustosRouter = Loadable({
  loader: () => import('containers/EmailCustos/EmailCustosRouter'),
  loading: AsyncLoader,
})

const SustentabilidadeRouter = Loadable({
  loader: () => import('components/Sustentabilidade/Sustentabilidade'),
  loading: AsyncLoader,
})

export {
  Session,
  Password,
  Main,
  Share,
  AgentesContainer,
  Dashboard,
  ConfiguracoesContainer,
  ConcessionariaRouter,
  ContratoRouter,
  CliqcceeRouter,
  ErrorContainer,
  FechamentoRouter,
  PldRouter,
  CotacaoRouter,
  OperacaoRouter,
  PedagioRouter,
  RelatorioRouter,
  RelatorioConsolidadoRouter,
  PreferenciasContainer,
  FaturamentoRouter,
  RateioRouter,
  MedicoesContainer,
  PainelEnergiaContainer,
  PerfilUsuario,
  ProjecoesRouter,
  EmailCustosRouter,
  ValidacaoContratosRouter,
  Arquivos,
  SustentabilidadeRouter,
}
