import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import toQueryString from './utils/toQueryString'
import Actions from './utils/actions'

const RESOURCE_NAME = 'ponto'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
    case Actions.CREATE:
      return `/acl/perfis/${id}/pontos`

    case Actions.SHOW:
    case Actions.UPDATE:
    case Actions.DESTROY:
      return `/acl/pontos/${id}`
    
    case 'tags_outros_pontos':
      return `/acl/pontos/${id}/tags_outros_pontos`

    default:
      return ''
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingPontoSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingPontoSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_PONTO_SUCCESS = 'LOADING_PONTO_SUCCESS'
export function loadingPontoSuccess(ponto) {
  return {
    type: LOADING_PONTO_SUCCESS,
    ponto,
  }
}

export const DESTROYING_PONTO_SUCCESS = 'DESTROYING_PONTO_SUCCESS'
export function destroyingPontoSuccess(pontoId) {
  return {
    type: DESTROYING_PONTO_SUCCESS,
    pontoId,
  }
}

export function fetchMultiplePonto(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.INDEX) + toQueryString(params)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchPonto(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.SHOW, id)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createPonto(parentId, ponto) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.CREATE, parentId), 'POST', ponto).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updatePonto(id, ponto) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.UPDATE, id), 'PUT', ponto).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyPonto(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(endpoint(Actions.DESTROY, id), 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

export function fetchTagsOutrosPontos(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('tags_outros_pontos', id)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

const ponto = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_PONTO_SUCCESS: {
      const { ponto: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_PONTO_SUCCESS:
      return state.delete(action.pontoId.toString())

    default:
      return state
  }
}

export default ponto
