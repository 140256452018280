import { Map } from 'immutable'

function mergePayload(state, payload) {
  if (payload.constructor === Array) {
    return new Map(
      payload.reduce((acc, item) => {
        acc[item.id] = item
        return acc
      }, {})
    )
  }
  return state.merge({
    [payload.id]: payload,
  })
}

export default mergePayload
