import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import toQueryString from './utils/toQueryString'

const RESOURCE_NAME = 'perfil'
const ENDPOINT = '/acl/perfis'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingPerfilSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingPerfilSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_PERFIL_SUCCESS = 'LOADING_PERFIL_SUCCESS'
export function loadingPerfilSuccess(perfil) {
  return {
    type: LOADING_PERFIL_SUCCESS,
    perfil,
  }
}

export const DESTROYING_PERFIL_SUCCESS = 'DESTROYING_PERFIL_SUCCESS'
export function destroyingPerfilSuccess(perfilId) {
  return {
    type: DESTROYING_PERFIL_SUCCESS,
    perfilId,
  }
}

export function fetchMultiplePerfil(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT + toQueryString(params)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchPerfil(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createPerfil(perfil) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT, 'POST', perfil).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updatePerfil(id, perfil) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`, 'PUT', perfil).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyPerfil(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(`${ENDPOINT}/${id}`, 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const perfil = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_PERFIL_SUCCESS: {
      const { perfil: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_PERFIL_SUCCESS:
      return state.delete(action.perfilId.toString())

    default:
      return state
  }
}

export default perfil
