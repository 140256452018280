import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import CardLink from '../../CardLink/CardLink'
// import { NottusIcon } from './icones/Meteorologia'
import FilterDramaIcon from '@material-ui/icons/FilterDrama'

const redeBasicaRedirect = () => {
  window.open('https://nottus.com.br/', '_blank').focus()
}

export const NottusLink = () => {
  const classes = useStyles()

  return (
    <Grid item sm={6} md={4} xl={3} className={classes.gridItem}>
      <CardLink
        title={'Nottus Meteorologia'}
        icon={<FilterDramaIcon />}
        action={redeBasicaRedirect}>
        Meteorologia
      </CardLink>
    </Grid>
  )
}

export const MenuLinkNottus = {
  icon: <FilterDramaIcon />,
  text: 'Nottus Meteorologia',
  action: redeBasicaRedirect,
}

const useStyles = makeStyles(theme => ({
  gridItem: {
    width: '100% !important',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
}))
