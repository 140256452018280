import { fetchCurrentUser } from 'api/clients/users'

const credenciais = {
  master_email: 'api@thymosenergia.com.br',
  password: 'd8ID1qRqRM4QKV9O',
  email: 'sofia.santos@thymosenergia.com.br',
}

export const getCredenciais = async client => {
  const usuario = await fetchCurrentUser()

  const loginEngecomp = usuario?.sso_logins_attributes?.find(
    elem => elem.tipo === 'engecomp'
  )

  if (client) {
    credenciais.email = 'marcos.brito@thymosenergia.com.br'
  }

  const credenciaisUser = loginEngecomp
    ? JSON.stringify({
        master_email: credenciais.master_email,
        password: credenciais.password,
        email: loginEngecomp.login,
      })
    : JSON.stringify(credenciais)

  return credenciaisUser
}
