import React from 'react'
import PropTypes from 'prop-types'
import AnimatedLoader from './AnimatedLoader'

const AsyncLoader = ({ isLoading, error }) => {
  if (isLoading) {
    return <AnimatedLoader />
  }

  if (error) {
    // eslint-disable-next-line no-console
    console.log(error)
    return <div>Sorry, there was a problem loading the page.</div>
  }

  return null
}

AsyncLoader.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isLoading: PropTypes.bool,
}

export default AsyncLoader
