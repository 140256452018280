import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import toQueryString from './utils/toQueryString'
import Actions from './utils/actions'

const RESOURCE_NAME = 'faturamento'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
    case Actions.CREATE:
      return '/acl/faturamentos'

    case Actions.SHOW:
    case Actions.UPDATE:
    case Actions.DESTROY:
      return `/acl/faturamentos/${id}`

    default:
      return `/acl/faturamentos/${id}/${action}`
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingFaturamentoSuccess(resource))
  return Promise.resolve(resource)
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_FATURAMENTO_SUCCESS = 'LOADING_FATURAMENTO_SUCCESS'
export function loadingFaturamentoSuccess(faturamento) {
  return {
    type: LOADING_FATURAMENTO_SUCCESS,
    faturamento,
  }
}

export function fetchMultipleFaturamento(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.INDEX) + toQueryString(params)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateFaturamento(id, faturamento) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.UPDATE, id), 'PUT', faturamento).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

const faturamento = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_FATURAMENTO_SUCCESS: {
      const { faturamento: payload } = action
      return mergePayload(state, payload)
    }

    default:
      return state
  }
}

export default faturamento
