import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'

const RESOURCE_NAME = 'classe'
const ENDPOINT = '/acl/classes'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingClasseSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingClasseSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_TIPO_PERFIL_SUCCESS = 'LOADING_TIPO_PERFIL_SUCCESS'
export function loadingClasseSuccess(classe) {
  return {
    type: LOADING_TIPO_PERFIL_SUCCESS,
    classe,
  }
}

export const DESTROYING_TIPO_PERFIL_SUCCESS = 'DESTROYING_TIPO_PERFIL_SUCCESS'
export function destroyingClasseSuccess(classeId) {
  return {
    type: DESTROYING_TIPO_PERFIL_SUCCESS,
    classeId,
  }
}

export function fetchMultipleClasse() {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchClasse(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createClasse(classe) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT, 'POST', classe).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateClasse(id, classe) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`, 'PUT', classe).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyClasse(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(`${ENDPOINT}/${id}`, 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const classe = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_TIPO_PERFIL_SUCCESS: {
      const { classe: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_TIPO_PERFIL_SUCCESS:
      return state.delete(action.classeId.toString())

    default:
      return state
  }
}

export default classe
