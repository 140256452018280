import { Map } from 'immutable'
import * as API from 'utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import toQueryString from './utils/toQueryString'

const RESOURCE_NAME = 'resumo'
const ENDPOINT = '/acl/resumo'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingResumoSuccess(resource))
  return Promise.resolve(resource)
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_RESUMO_SUCCESS = 'LOADING_RESUMO_SUCCESS'
export function loadingResumoSuccess(resumo) {
  return {
    type: LOADING_RESUMO_SUCCESS,
    resumo,
  }
}

export function fetchResumoEnergia(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/energia${toQueryString(params)}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchResumoEnergiaPerfil(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/energia_perfil${toQueryString(params)}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function downloadResumoEnergia(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.callWithResponse(
      `${ENDPOINT}/download${toQueryString(params)}`
    ).then(
      resource => {
        dispatch(loadingSuccess(RESOURCE_NAME))
        return Promise.resolve(resource)
      },
      error => reject(dispatch, error)
    )
  }
}

export function downloadResumoMedicoes(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.callWithResponse(
      `${ENDPOINT}/download_medicoes${toQueryString(params)}`
    ).then(
      resource => {
        dispatch(loadingSuccess(RESOURCE_NAME))
        return Promise.resolve(resource)
      },
      error => reject(dispatch, error)
    )
  }
}

const resumo = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_RESUMO_SUCCESS: {
      const { resumo: payload } = action
      return state.merge(payload)
    }

    default:
      return state
  }
}

export default resumo
