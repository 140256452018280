import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import Actions from './utils/actions'

const RESOURCE_NAME = 'parametroPonto'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
    case Actions.CREATE:
      return `/acl/pontos/${id}/parametros_ponto`

    case Actions.SHOW:
    case Actions.UPDATE:
    case Actions.DESTROY:
      return `/acl/parametros_ponto/${id}`

    case 'import':
      return '/acl/parametros_ponto/import'

    default:
      return ''
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingParametroPontoSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingParametroPontoSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_PARAMETRO_PONTO_SUCCESS = 'LOADING_PARAMETRO_PONTO_SUCCESS'
export function loadingParametroPontoSuccess(parametroPonto) {
  return {
    type: LOADING_PARAMETRO_PONTO_SUCCESS,
    parametroPonto,
  }
}

export const DESTROYING_PARAMETRO_PONTO_SUCCESS =
  'DESTROYING_PARAMETRO_PONTO_SUCCESS'
export function destroyingParametroPontoSuccess(parametroPontoId) {
  return {
    type: DESTROYING_PARAMETRO_PONTO_SUCCESS,
    parametroPontoId,
  }
}

export function fetchMultipleParametroPonto(parentId) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.INDEX, parentId)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchParametroPonto(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.SHOW, id)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createParametroPonto(parentId, parametroPonto) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(
      endpoint(Actions.CREATE, parentId),
      'POST',
      parametroPonto
    ).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateParametroPonto(id, parametroPonto) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.UPDATE, id), 'PUT', parametroPonto).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyParametroPonto(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(endpoint(Actions.DESTROY, id), 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

export function importParametroPonto(multipleParametroPonto) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint('import'), 'POST', multipleParametroPonto).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

const parametroPonto = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_PARAMETRO_PONTO_SUCCESS: {
      const { parametroPonto: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_PARAMETRO_PONTO_SUCCESS:
      return state.delete(action.parametroPontoId.toString())

    default:
      return state
  }
}

export default parametroPonto
