import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'

const RESOURCE_NAME = 'subgrupo'
const ENDPOINT = '/subgrupos'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingSubgrupoSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingSubgrupoSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_SUBGRUPO_SUCCESS = 'LOADING_SUBGRUPO_SUCCESS'
export function loadingSubgrupoSuccess(subgrupo) {
  return {
    type: LOADING_SUBGRUPO_SUCCESS,
    subgrupo,
  }
}

export const DESTROYING_SUBGRUPO_SUCCESS = 'DESTROYING_SUBGRUPO_SUCCESS'
export function destroyingSubgrupoSuccess(subgrupoId) {
  return {
    type: DESTROYING_SUBGRUPO_SUCCESS,
    subgrupoId,
  }
}

export function fetchMultipleSubgrupo() {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchSubgrupo(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createSubgrupo(subgrupo) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT, 'POST', subgrupo).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateSubgrupo(id, subgrupo) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`, 'PUT', subgrupo).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroySubgrupo(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(`${ENDPOINT}/${id}`, 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const subgrupo = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_SUBGRUPO_SUCCESS: {
      const { subgrupo: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_SUBGRUPO_SUCCESS:
      return state.delete(action.subgrupoId.toString())

    default:
      return state
  }
}

export default subgrupo
