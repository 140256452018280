import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import toQueryString from './utils/toQueryString'

const RESOURCE_NAME = 'vigenciaValor'
const ENDPOINT = '/gestao_bandeiras/valores'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingVigenciaValorSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingVigenciaValorSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_VIGENCIA_VALOR_SUCCESS = 'LOADING_VIGENCIA_VALOR_SUCCESS'
export function loadingVigenciaValorSuccess(vigenciaValor) {
  return {
    type: LOADING_VIGENCIA_VALOR_SUCCESS,
    vigenciaValor,
  }
}

export const DESTROYING_VIGENCIA_VALOR_SUCCESS = 'DESTROYING_VIGENCIA_VALOR_SUCCESS'
export function destroyingVigenciaValorSuccess(vigenciaValorId) {
  return {
    type: DESTROYING_VIGENCIA_VALOR_SUCCESS,
    vigenciaValorId,
  }
}

export function fetchMultipleVigenciaValor(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT + toQueryString(params)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchVigenciaValor(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createVigenciaValor(vigenciaValor) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT, 'POST', vigenciaValor).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateVigenciaValor(id, vigenciaValor) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`, 'PUT', vigenciaValor).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyVigenciaValor(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(`${ENDPOINT}/${id}`, 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const vigenciaValor = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_VIGENCIA_VALOR_SUCCESS: {
      const { vigenciaValor: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_VIGENCIA_VALOR_SUCCESS:
      return state.delete(action.vigenciaValorId.toString())

    default:
      return state
  }
}

export default vigenciaValor
