import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'

const RESOURCE_NAME = 'classeConsumo'
const ENDPOINT = '/classes_consumo'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingClasseConsumoSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingClasseConsumoSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_CLASSE_CONSUMO_SUCCESS = 'LOADING_CLASSE_CONSUMO_SUCCESS'
export function loadingClasseConsumoSuccess(classeConsumo) {
  return {
    type: LOADING_CLASSE_CONSUMO_SUCCESS,
    classeConsumo,
  }
}

export const DESTROYING_CLASSE_CONSUMO_SUCCESS =
  'DESTROYING_CLASSE_CONSUMO_SUCCESS'
export function destroyingClasseConsumoSuccess(classeConsumoId) {
  return {
    type: DESTROYING_CLASSE_CONSUMO_SUCCESS,
    classeConsumoId,
  }
}

export function fetchMultipleClasseConsumo() {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchClasseConsumo(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createClasseConsumo(classeConsumo) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT, 'POST', classeConsumo).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateClasseConsumo(id, classeConsumo) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`, 'PUT', classeConsumo).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyClasseConsumo(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(`${ENDPOINT}/${id}`, 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const classeConsumo = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_CLASSE_CONSUMO_SUCCESS: {
      const { classeConsumo: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_CLASSE_CONSUMO_SUCCESS:
      return state.delete(action.classeConsumoId.toString())

    default:
      return state
  }
}

export default classeConsumo
