import React from 'react'
import PropTypes from 'prop-types'

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { /*blue, pink,*/ grey, common } from '@material-ui/core/colors'
import deepmerge from 'deepmerge'

export const ELECTRIC_BASE = '#371126'
export const ELECTRIC_GREEN = '#00A978'
export const ELECTRIC_PURPLE = '#9E1F6C'
export const ELECTRIC_BLUE = '#3D97BA'
export const ELECTRIC_YELLOW = '#F7A823'
export const ELECTRIC_RED = '#EF4136'
export const THYMOS_ORANGE = '#F47835'
export const COPREL_BLUE = '#1b1484'
export const FUSAO_GREEN = '#7ed321'
export const EGRID_BLUE = '#5067B9'

const productionColor = () => {
  const company = process.env.REACT_APP_COMPANY_NAME

  switch (company) {
    case 'Electric':
      return ELECTRIC_BLUE
    case 'Thymos':
      return THYMOS_ORANGE
    case 'Coprel':
      return COPREL_BLUE
    case 'Fusao':
      return FUSAO_GREEN
    default:
      return ELECTRIC_BLUE
  }
}

const PROD_PRIMARY_COLOR = productionColor()

const PRIMARY_COLOR =
  process.env.REACT_APP_STAGE === 'production'
    ? PROD_PRIMARY_COLOR
    : process.env.NODE_ENV === 'production'
    ? EGRID_BLUE
    : PROD_PRIMARY_COLOR

const getCookie = name => {
  const regex = new RegExp(`(?:(?:^|.*;*)${name}*=*([^;]*).*$)|^.*$`)
  return document.cookie.replace(regex, '$1')
}

const themeInitialOptions = {
  dense: false,
}

const usingHighDensity = themeOptions =>
  deepmerge(themeOptions, {
    props: {
      MuiButton: {
        size: 'small',
      },
      MuiFilledInput: {
        margin: 'dense',
      },
      MuiFormControl: {
        margin: 'dense',
      },
      MuiFormHelperText: {
        margin: 'dense',
      },
      MuiIconButton: {
        size: 'small',
      },
      MuiInputBase: {
        margin: 'dense',
      },
      MuiInputLabel: {
        margin: 'dense',
      },
      MuiListItem: {
        dense: true,
      },
      MuiOutlinedInput: {
        margin: 'dense',
      },
      MuiFab: {
        size: 'small',
      },
      MuiTable: {
        size: 'small',
      },
      MuiTextField: {
        margin: 'dense',
      },
      MuiToolbar: {
        variant: 'dense',
      },
    },
    overrides: {
      MuiIconButton: {
        sizeSmall: {
          // minimal touch target hit spacing
          marginLeft: 4,
          marginRight: 4,
          padding: 12,
        },
      },
    },
  })

const usingIdentity = themeOptions => themeOptions

export const DispatchContext = React.createContext(() => {
  throw new Error('Forgot to wrap component in ThemeContext.Provider')
})

export function ThemeProvider(props) {
  const { children } = props

  const [themeOptions, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case 'SET_DENSE':
        return {
          ...state,
          dense: action.payload,
        }
      case 'RESET_DENSITY':
        return {
          ...state,
          dense: themeInitialOptions.dense,
        }
      case 'CHANGE':
        return {
          ...state,
          paletteType: action.payload.paletteType || state.paletteType,
        }
      default:
        throw new Error(`Unrecognized type ${action.type}`)
    }
  }, themeInitialOptions)

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const preferredType = prefersDarkMode ? 'dark' : 'light'
  const { dense, paletteType = preferredType } = themeOptions

  React.useEffect(() => {
    if (process.browser) {
      const nextPaletteType = getCookie('paletteType')

      dispatch({
        type: 'CHANGE',
        payload: { paletteType: nextPaletteType },
      })
    }
  }, [])

  // persist paletteType
  React.useEffect(() => {
    document.cookie = `paletteType=${paletteType};path=/;max-age=31536000`
  }, [paletteType])

  const theme = React.useMemo(() => {
    const themeDecorator = dense ? usingHighDensity : usingIdentity
    const nextTheme = createMuiTheme(
      themeDecorator({
        palette: {
          error: {
            main: ELECTRIC_RED,
          },
          primary: {
            main: PRIMARY_COLOR,
          },
          secondary: {
            main: ELECTRIC_BASE,
          },
          type: paletteType,
          background: {
            default: paletteType === 'light' ? '#fff' : '#121212', // '#fafafa' : '#303030'
          },
        },
        typography: {
          fontFamily: [
            'Google Sans',
            '"Roboto"',
            '"Helvetica"',
            '"Arial"',
            'sans-serif',
          ].join(','),
        },
        drawerWidth: 320,
        logo: {
          background: ELECTRIC_BASE,
          color: paletteType === 'light' ? common.black : common.white,
          blue: ELECTRIC_BLUE,
          purple: ELECTRIC_PURPLE,
          green: ELECTRIC_GREEN,
          yellow: ELECTRIC_YELLOW,
        },
        toolbars: {
          main: {
            backgroundColor: paletteType === 'light' ? grey[200] : grey[900],
            borderColor: paletteType === 'light' ? grey[300] : grey[700],
          },
          breadcrumb: {
            backgroundColor: paletteType === 'light' ? grey[100] : grey[900],
            borderColor: paletteType === 'light' ? grey[300] : grey[700],
            iconColor: paletteType === 'light' ? grey[400] : grey[800],
          },
          actions: {
            borderColor: paletteType === 'light' ? grey[200] : grey[800],
          },
        },
        defaultPadding: {
          paddingLeft: `${8 * 2}px`,
          paddingRight: `${8 * 2}px`,
        },
        overrides: {
          MuiTouchRipple: {
            child: {
              backgroundColor: 'white',
            },
          },
          MuiInputLabel: {
            root: {
              '&$focused': {
                color: ELECTRIC_BLUE,
              },
              '&$error  ': {
                color: `${ELECTRIC_RED} !important`,
              },
            },
          },
          MuiCardContent: {
            root: {
              '&:last-child': {
                paddingBottom: 16,
              },
            },
          },
          MuiCard: {
            root: {
              overflow: 'unset',
              transition: '0.3s',
              borderRadius: 16,
              boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
              '&:hover': {
                boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
              },
              '@media print': {
                boxShadow: 'none',
                backgroundColor: '#F0F0F0',
                pageBreakInside: 'avoid',
                display: 'block',
                '&:hover': {
                  boxShadow: 'none',
                },
              },
            },
          },
        },
      })
    )

    nextTheme.palette.background.level2 =
      paletteType === 'light' ? nextTheme.palette.grey[100] : '#333'

    nextTheme.palette.background.level1 =
      paletteType === 'light' ? '#fff' : nextTheme.palette.grey[900]

    const base = nextTheme.palette.augmentColor({ main: ELECTRIC_BASE })
    const blue = nextTheme.palette.augmentColor({ main: ELECTRIC_BLUE })
    const green = nextTheme.palette.augmentColor({ main: ELECTRIC_GREEN })
    const purple = nextTheme.palette.augmentColor({ main: ELECTRIC_PURPLE })
    const red = nextTheme.palette.augmentColor({ main: ELECTRIC_RED })
    const yellow = nextTheme.palette.augmentColor({ main: ELECTRIC_YELLOW })
    const gray = nextTheme.palette.augmentColor({ main: grey[400] })
    const blueGray =
      paletteType === 'light'
        ? {
            main: '#F2F4F8',
            light: '#F7F9FC',
            dark: '#E3E5E8',
            contrastText: '#2A3040',
          }
        : {
            main: '#2A3040',
            light: '#32394D',
            dark: '#222633',
            contrastText: '#F2F4F8',
          }

    return deepmerge(nextTheme, {
      colors: {
        negative: red,
        positive: green,
        warning: yellow,

        base,
        blue,
        green,
        purple,
        red,
        yellow,
        gray,
        blueGray,
      },
      overrides: {
        ...{
          ...(paletteType !== 'light' && {
            MuiPaper: {
              root: {
                backgroundColor: blueGray.main,
              },
            },
          }),
        },
      },
    })
  }, [dense, paletteType])

  React.useEffect(() => {
    // Expose the theme as a global variable so people can play with it.
    if (process.browser) {
      window.theme = theme
    }
  }, [theme])

  return (
    <MuiThemeProvider theme={theme}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </MuiThemeProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
}

export function useChangeTheme() {
  const dispatch = React.useContext(DispatchContext)
  return React.useCallback(
    options => dispatch({ type: 'CHANGE', payload: options }),
    [dispatch]
  )
}
