import instance from '../api'

export const createSession = async (email, password) =>
  instance
    .post('/user_token', {
      auth: {
        email,
        password,
      },
    })
    .then(response => response)

export const fetchUser = async id =>
  instance.get(`/users/${id}`).then(response => response)
