import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'

const RESOURCE_NAME = 'enquadramento'
const ENDPOINT = '/enquadramentos'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingEnquadramentoSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingEnquadramentoSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_ENQUADRAMENTO_SUCCESS = 'LOADING_ENQUADRAMENTO_SUCCESS'
export function loadingEnquadramentoSuccess(enquadramento) {
  return {
    type: LOADING_ENQUADRAMENTO_SUCCESS,
    enquadramento,
  }
}

export const DESTROYING_ENQUADRAMENTO_SUCCESS =
  'DESTROYING_ENQUADRAMENTO_SUCCESS'
export function destroyingEnquadramentoSuccess(enquadramentoId) {
  return {
    type: DESTROYING_ENQUADRAMENTO_SUCCESS,
    enquadramentoId,
  }
}

export function fetchMultipleEnquadramento() {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchEnquadramento(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createEnquadramento(enquadramento) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT, 'POST', enquadramento).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateEnquadramento(id, enquadramento) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/${id}`, 'PUT', enquadramento).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyEnquadramento(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(`${ENDPOINT}/${id}`, 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const enquadramento = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_ENQUADRAMENTO_SUCCESS: {
      const { enquadramento: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_ENQUADRAMENTO_SUCCESS:
      return state.delete(action.enquadramentoId.toString())

    default:
      return state
  }
}

export default enquadramento
