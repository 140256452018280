import 'App.css'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { defaultFlags } from './contexts/flags'
import * as serviceWorker from './serviceWorker'
import 'App.css'
import './i18n'

if (process.browser) {
  // eslint-disable-next-line no-console
  console.log(
    `
      ███████╗ ██████╗ ██████╗ ██╗██████╗
      ██╔════╝██╔════╝ ██╔══██╗██║██╔══██╗
      █████╗  ██║  ███╗██████╔╝██║██║  ██║
      ██╔══╝  ██║   ██║██╔══██╗██║██║  ██║
      ███████╗╚██████╔╝██║  ██║██║██████╔╝
      ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═╝╚═════╝

    ${window.location.origin}
    `
  )
}

// eslint-disable-next-line no-extra-semi
;(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: '66ecac924e532c0fccfe8ed0',
    options: {
      bootstrap: {
        ...defaultFlags,
      },
    },
  })

  ReactDOM.render(
    <LDProvider>
      <App />
    </LDProvider>,
    document.getElementById('root')
  )
})()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register()
