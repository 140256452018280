import React from 'react'

import { removeNotification } from 'redux/modules/notifier'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'

const Notifier = () => {
  const [displayed, setDisplayed] = React.useState([])

  const notifications = useSelector(state => state.notifier.notifications)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  React.useEffect(() => {
    const storeDisplayed = key => {
      setDisplayed([...displayed, key])
    }

    notifications.forEach(notification => {
      setTimeout(() => {
        // If notification already displayed, abort
        if (displayed.indexOf(notification.key) > -1) return

        // Display notification using notistack
        enqueueSnackbar(notification.message, notification.options)

        // Add notification's key to the local state
        storeDisplayed(notification.key)

        // Dispatch action to remove the notification from the redux store
        dispatch(removeNotification(notification.key))
      }, 1)
    })
  }, [dispatch, enqueueSnackbar, displayed, notifications])

  return null
}

export default Notifier
