import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import toQueryString from './utils/toQueryString'

const RESOURCE_NAME = 'resumoCotacoes'
const ENDPOINT = '/acl/resumo_cotacoes'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingResumoCotacaoSuccess(resource))
  return Promise.resolve(resource)
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_RESUMO_COTACAO_SUCCESS = 'LOADING_RESUMO_COTACAO_SUCCESS'
export function loadingResumoCotacaoSuccess(resumoCotacao) {
  return {
    type: LOADING_RESUMO_COTACAO_SUCCESS,
    resumoCotacao,
  }
}

export function fetchMultipleResumoCotacao(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT + toQueryString(params)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchDashboardResumoCotacao(params = {}) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(`${ENDPOINT}/dashboard${toQueryString(params)}`).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

const resumoCotacao = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_RESUMO_COTACAO_SUCCESS: {
      const { resumoCotacao: payload } = action
      return mergePayload(state, payload)
    }

    default:
      return state
  }
}

export default resumoCotacao
