import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'

const RESOURCE_NAME = 'pldSemanal'
const ENDPOINT = '/indicativos/semanais'

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingPldMedioSuccess(resource))
  return Promise.resolve(resource)
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_PLD_SEMANAL_SUCCESS = 'LOADING_PLD_SEMANAL_SUCCESS'
export function loadingPldMedioSuccess(pldSemanal) {
  return {
    type: LOADING_PLD_SEMANAL_SUCCESS,
    pldSemanal,
  }
}

export function updatePldSemanal(pldSemanal) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(ENDPOINT, 'PUT', pldSemanal).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

const pldSemanal = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_PLD_SEMANAL_SUCCESS: {
      const { pldSemanal: payload } = action
      return mergePayload(state, payload)
    }

    default:
      return state
  }
}

export default pldSemanal
