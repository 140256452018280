import { Map } from 'immutable'
import * as API from '../../utils/API'
import { loading, loadingSuccess, loadingFailure } from './status'
import mergePayload from './utils/mergePayload'
import Actions from './utils/actions'

const RESOURCE_NAME = 'custoInicialPerfil'

const endpoint = (action, id) => {
  switch (action) {
    case Actions.INDEX:
    case Actions.CREATE:
      return `/acl/perfis/${id}/custos_iniciais`

    case Actions.SHOW:
    case Actions.UPDATE:
    case Actions.DESTROY:
      return `/acl/custos_iniciais/${id}`

    default:
      return ''
  }
}

const resolve = (dispatch, resource) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(loadingCustoInicialPerfilSuccess(resource))
  return Promise.resolve(resource)
}

const resolveDestroy = (dispatch, id) => {
  dispatch(loadingSuccess(RESOURCE_NAME))
  dispatch(destroyingCustoInicialPerfilSuccess(id))
  return Promise.resolve()
}

const reject = (dispatch, error) => {
  dispatch(loadingFailure(RESOURCE_NAME, error))
  return Promise.reject(error)
}

export const LOADING_CUSTO_INCIAL_PERFIL_SUCCESS = 'LOADING_CUSTO_INCIAL_PERFIL_SUCCESS'
export function loadingCustoInicialPerfilSuccess(custoInicialPerfil) {
  return {
    type: LOADING_CUSTO_INCIAL_PERFIL_SUCCESS,
    custoInicialPerfil,
  }
}

export const DESTROYING_CUSTO_INCIAL_PERFIL_SUCCESS = 'DESTROYING_CUSTO_INCIAL_PERFIL_SUCCESS'
export function destroyingCustoInicialPerfilSuccess(custoId) {
  return {
    type: DESTROYING_CUSTO_INCIAL_PERFIL_SUCCESS,
    custoId,
  }
}

export function fetchMultipleCustoInicialPerfil(parentId) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.INDEX, parentId)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function fetchCustoInicialPerfil(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.SHOW, id)).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function createCustoInicialPerfil(parentId, custoInicialPerfil) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.CREATE, parentId), 'POST', custoInicialPerfil).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function updateCustoInicialPerfil(id, custoInicialPerfil) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return API.call(endpoint(Actions.UPDATE, id), 'PUT', custoInicialPerfil).then(
      resource => resolve(dispatch, resource),
      error => reject(dispatch, error)
    )
  }
}

export function destroyCustoInicialPerfil(id) {
  return async function (dispatch /* getState */) {
    dispatch(loading(RESOURCE_NAME))
    return await API.call(endpoint(Actions.DESTROY, id), 'DELETE').then(
      () => resolveDestroy(dispatch, id),
      error => reject(dispatch, error)
    )
  }
}

const custoInicialPerfil = (state = new Map({}), action) => {
  switch (action.type) {
    case LOADING_CUSTO_INCIAL_PERFIL_SUCCESS: {
      const { custoInicialPerfil: payload } = action
      return mergePayload(state, payload)
    }

    case DESTROYING_CUSTO_INCIAL_PERFIL_SUCCESS:
      return state.delete(action.custoId.toString())

    default:
      return state
  }
}

export default custoInicialPerfil
