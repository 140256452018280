import React from 'react'
import PropTypes from 'prop-types'

const initialState = {
  contextual: false,
  pageTitle: '',
  isDrawerOpen: false,
  darkMode: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_DRAWER':
      return { ...state, isDrawerOpen: !state.isDrawerOpen }
    case 'SET_DARK_MODE':
      return { ...state, darkMode: action.payload }
    case 'SET_PAGE_TITLE':
      return {
        ...state,
        pageTitle:
          (typeof action.payload === 'string' && action.payload.trim()) ||
          action.payload ||
          state.pageTitle,
      }
    case 'SET_CONTEXTUAL':
      return { ...state, contextual: action.payload }
    default:
      return state
  }
}

const AppStateContext = React.createContext()
const AppDispatchContext = React.createContext(() => {
  throw new Error('Forgot to wrap component in AppContext.Provider')
})

const AppProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  )
}

AppProvider.propTypes = {
  children: PropTypes.node,
}

const useAppState = () => {
  const context = React.useContext(AppStateContext)
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppProvider')
  }
  return context
}

const useAppDispatch = () => {
  const context = React.useContext(AppDispatchContext)
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppProvider')
  }
  return context
}

const useSetTitle = () => {
  const dispatch = React.useContext(AppDispatchContext)
  return React.useCallback(
    title => dispatch({ type: 'SET_PAGE_TITLE', payload: title }),
    [dispatch]
  )
}

export { AppProvider, useAppState, useAppDispatch, useSetTitle }
